
import api from '../../../services/api';
import { useAuth } from '../../../components/AuthProvider';
import React, { useState, useEffect } from 'react';
import { Mail, Plus, SendHorizontal } from 'lucide-react';


const InviteUsersForm = () => {
    const [invites, setInvites] = useState([{ email: '', role: 'shipping' }]);
    const [loading, setLoading] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [pageLoading, setPageLoading] = useState(true);
    const { getUser } = useAuth();
    const userData = getUser();
    const user = userData; // For consistency with your existing code
  
    useEffect(() => {
      const fetchBusinessName = async () => {
        try {
          if (!user?.organizationId) {
            console.log('No organization ID available');
            setPageLoading(false);
            return;
          }
  
          const response = await api.get(`/organizations/${user.organizationId}`);
          console.log('Organization API Response:', response.data);
          
          if (response.data && response.data.data) {
            setBusinessName(response.data.data.business_name);
          }
        } catch (error) {
          console.error('Error fetching business name:', error);
          if (error.response) {
            console.log('Error response:', {
              status: error.response.status,
              data: error.response.data
            });
          }
          setBusinessName('');
        } finally {
          setPageLoading(false);
        }
      };
      fetchBusinessName();
    }, [user]);
  
    const handleAddInvite = () => {
      setInvites([...invites, { email: '', role: 'shipping' }]);
    };
  
    const handleInviteChange = (index, field, value) => {
      const newInvites = [...invites];
      newInvites[index][field] = value;
      setInvites(newInvites);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        await api.post('/business/invite-users', {
          merchantId: user?.id,
          businessName,
          organizationId: user?.organizationId,
          invites
        });
        
        // Reset form
        setInvites([{ email: '', role: 'shipping' }]);
        alert('Team members invited successfully!');
      } catch (error) {
        console.error('Invite error:', error);
        alert(error.response?.data?.message || 'Failed to send invitations');
      } finally {
        setLoading(false);
      }
    };
  
    if (pageLoading) {
      return <div>Loading...</div>;
    }
  
    if (!user?.organizationId) {
      return <div>You need to be part of an organization to invite team members.</div>;
    }
  
    if (!businessName) {
      return <div>Unable to fetch organization details. Please try again later.</div>;
    }

  return (
    <div className="grow">
      <div className="p-6 space-y-6">
        {/* Page Title */}
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold">Invite Team Members</h2>

        {/* Explanation Section */}
        <div className="mb-6">
          <h3 className="text-slate-800 dark:text-slate-100 font-semibold mb-2">Team Access</h3>
          <p className="text-sm text-slate-500 dark:text-slate-400">
            Invite team members to manage shipping operations or finance. Each invited user will receive an email invitation to join your organization.
          </p>
        </div>

        {/* Invite Form */}
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            {invites.map((invite, index) => (
              <div key={index} className="flex gap-4">
                {/* Email Field */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-slate-600 dark:text-slate-300 mb-1" htmlFor={`email-${index}`}>
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      id={`email-${index}`}
                      type="email"
                      className="form-input w-full px-3 py-2 bg-white dark:bg-slate-800 border rounded-sm text-sm focus:border-blue-500 dark:border-slate-700"
                      placeholder="colleague@company.com"
                      value={invite.email}
                      onChange={(e) => handleInviteChange(index, 'email', e.target.value)}
                      required
                    />
                    <div className="absolute right-0 top-0 h-full px-3 flex items-center">
                      <Mail className="w-4 h-4 text-slate-400" />
                    </div>
                  </div>
                </div>

                {/* Role Selection */}
                <div className="w-48">
                  <label className="block text-sm font-medium text-slate-600 dark:text-slate-300 mb-1" htmlFor={`role-${index}`}>
                    Role
                  </label>
                  <select
                    id={`role-${index}`}
                    className="form-select w-full px-3 py-2 bg-white dark:bg-slate-800 border rounded-sm text-sm focus:border-blue-500 dark:border-slate-700"
                    value={invite.role}
                    onChange={(e) => handleInviteChange(index, 'role', e.target.value)}
                  >
                    <option value="shipping">Shipping</option>
                    <option value="finance">Finance</option>
                  </select>
                </div>
              </div>
            ))}
          </div>

          {/* Add More Button */}
          <button
            type="button"
            onClick={handleAddInvite}
            className="mt-4 inline-flex items-center text-sm font-medium text-blue-500 hover:text-blue-600"
          >
            <Plus className="w-4 h-4 mr-1" />
            Add Another
          </button>

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="btn bg-blue-500 hover:bg-blue-600 text-white disabled:opacity-50 disabled:cursor-not-allowed inline-flex items-center"
              disabled={loading}
            >
              <SendHorizontal className="w-4 h-4 mr-2" />
              {loading ? 'Sending...' : 'Send Invitations'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InviteUsersForm;