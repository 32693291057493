// MerchantSettings.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';

import { 
  Container, 
  Typography, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  FormLabel, 
  TextField, 
  Button, 
  Paper, 
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Snackbar,
  Alert,
  Switch,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import api from '../../services/api';

const ADDRESS_TYPES = [
  'Warehouse',
  'Office',
  'Distribution Center',
  'Return Center',
  'Other'
];
const MerchantSettings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [addresses, setAddresses] = useState([]);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [newAddress, setNewAddress] = useState({
    address_type: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    isPrimary: false
  });


  const [settings, setSettings] = useState({
    use_own_fedex_account: false, // Changed to boolean instead of null
    fedex_account_number: '',
    payment_capture_method: '', // Keep as empty string initially
    stripe_email: '',
    iban: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  const { getUser } = useAuth();
  const userData = getUser();
  const merchantId = userData?.id;


  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await api.get(`/settings/${merchantId}`);
        if (response.data) {
          setSettings({
            use_own_fedex_account: response.data.use_own_fedex_account || false,
            fedex_account_number: response.data.fedex_account_number || '',
            payment_capture_method: response.data.payment_capture_method || '',
            stripe_email: response.data.stripe_email || '',
            iban: response.data.iban || ''
          });
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load settings. Please try again.',
          severity: 'error'
        });
      }
    };

    if (merchantId) {
      fetchSettings();
    }
  }, [merchantId]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await api.get(`/org-address/${merchantId}/addresses`);
        setAddresses(response.data);
      } catch (error) {
        console.error('Error fetching addresses:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load addresses. Please try again.',
          severity: 'error'
        });
      }
    };

    if (merchantId) {
      fetchAddresses();
    }
  }, [merchantId]);

  const handleAddressChange = (e) => {
    const { name, value, checked } = e.target;
    setNewAddress(prev => ({
      ...prev,
      [name]: name === 'isPrimary' ? checked : value
    }));
  };

  const handleAddressSave = async () => {
    try {
      if (editingAddressId) {
        await api.put(`/org-address/${merchantId}/addresses/${editingAddressId}`, newAddress);
      } else {
        await api.post(`/org-address/${merchantId}/addresses`, newAddress);
      }
      
      // Refresh addresses
      const response = await api.get(`/org-address/${merchantId}/addresses`);
      setAddresses(response.data);
      
      // Reset form
      setNewAddress({
        address_type: '',
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        isPrimary: false
      });
      setShowAddressForm(false);
      setEditingAddressId(null);
      
      setSnackbar({
        open: true,
        message: `Address ${editingAddressId ? 'updated' : 'added'} successfully!`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving address:', error);
      setSnackbar({
        open: true,
        message: 'Failed to save address. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleEditAddress = (address) => {
    setNewAddress(address);
    setEditingAddressId(address.id);
    setShowAddressForm(true);
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      await api.delete(`/org-address/${merchantId}/addresses/${addressId}`);
      setAddresses(addresses.filter(addr => addr.id !== addressId));
      setSnackbar({
        open: true,
        message: 'Address deleted successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting address:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete address. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleSetPrimary = async (addressId) => {
    try {
      await api.put(`/org-address/${merchantId}/addresses/${addressId}/set-primary`);
      const updatedAddresses = addresses.map(addr => ({
        ...addr,
        isPrimary: addr.id === addressId
      }));
      setAddresses(updatedAddresses);
      setSnackbar({
        open: true,
        message: 'Primary address updated successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error setting primary address:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update primary address. Please try again.',
        severity: 'error'
      });
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({ ...prev, [name]: value }));
  };

  const handleFedExChange = (e) => {
    const value = e.target.value === 'true';
    setSettings(prev => ({ 
      ...prev, 
      use_own_fedex_account: value,
      fedex_account_number: value ? prev.fedex_account_number : ''
    }));
  };

  const handlePaymentMethodChange = (e) => {
    const { value } = e.target;
    setSettings(prev => ({ 
      ...prev, 
      payment_capture_method: value,
      stripe_email: value === 'stripe_auth_hold' ? prev.stripe_email : '',
      iban: value === '3shepherd_escrow' ? prev.iban : ''
    }));
  };

  const validateSettings = () => {
    // Basic validation for required fields
    if (settings.use_own_fedex_account && !settings.fedex_account_number) {
      return 'FedEx account number is required when using own account';
    }
    
    if (!settings.payment_capture_method) {
      return 'Please select a payment method';
    }
    
    if (settings.payment_capture_method === 'stripe_auth_hold' && !settings.stripe_email) {
      return 'Stripe email is required for Stripe Auth Hold';
    }
    
    if (settings.payment_capture_method === '3shepherd_escrow' && !settings.iban) {
      return 'IBAN is required for 3Shepherd Escrow';
    }
    
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const validationError = validateSettings();
    if (validationError) {
      setSnackbar({
        open: true,
        message: validationError,
        severity: 'error'
      });
      return;
    }

    try {
      const dataToSubmit = {
        ...settings,
        use_own_fedex_account: Boolean(settings.use_own_fedex_account)
      };
      
      await api.put(`/settings/${merchantId}`, dataToSubmit);
      setSnackbar({
        open: true,
        message: 'Settings updated successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating settings:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update settings. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      {!isMobile && <Sidebar />}
      <Box flexGrow={2}>
        <Navbar />
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Your Preferences
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth required>
                    <FormLabel component="legend">FedEx Account</FormLabel>
                    <RadioGroup
                      name="fedex_account"
                      value={settings.use_own_fedex_account.toString()}
                      onChange={handleFedExChange}
                    >
                      <FormControlLabel 
                        value="false" 
                        control={<Radio />} 
                        label="Use 3Shepherd FedEx Account" 
                      />
                      <FormControlLabel 
                        value="true" 
                        control={<Radio />} 
                        label="Use Own FedEx Account" 
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {settings.use_own_fedex_account && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="FedEx Account Number"
                      name="fedex_account_number"
                      value={settings.fedex_account_number}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth required>
                    <FormLabel component="legend">Payment Method</FormLabel>
                    <RadioGroup
                      name="payment_capture_method"
                      value={settings.payment_capture_method}
                      onChange={handlePaymentMethodChange}
                    >
                      <FormControlLabel 
                        value="3shepherd_escrow" 
                        control={<Radio />} 
                        label="3Shepherd Escrow" 
                      />
                      <FormControlLabel 
                        value="stripe_auth_hold" 
                        control={<Radio />} 
                        label="Stripe Auth Hold" 
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {settings.payment_capture_method === 'stripe_auth_hold' && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Stripe Email"
                      name="stripe_email"
                      type="email"
                      value={settings.stripe_email}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}

                {settings.payment_capture_method === '3shepherd_escrow' && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="IBAN"
                      name="iban"
                      value={settings.iban}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    size="large"
                    fullWidth={isMobile}
                  >
                    Save Settings
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 } }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h4" component="h2">
                Warehouse Addresses
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setShowAddressForm(true);
                  setEditingAddressId(null);
                  setNewAddress({
                    address_type: '',
                    address_line1: '',
                    address_line2: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    country: '',
                    isPrimary: false
                  });
                }}
              >
                Add Address
              </Button>
            </Box>

            {showAddressForm && (
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel>Address Type</FormLabel>
                        <Select
                          name="address_type"
                          value={newAddress.address_type}
                          onChange={handleAddressChange}
                          required
                        >
                          {ADDRESS_TYPES.map(type => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Address Line 1"
                        name="address_line1"
                        value={newAddress.address_line1}
                        onChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Address Line 2"
                        name="address_line2"
                        value={newAddress.address_line2}
                        onChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="City"
                        name="city"
                        value={newAddress.city}
                        onChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="State"
                        name="state"
                        value={newAddress.state}
                        onChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Postal Code"
                        name="postal_code"
                        value={newAddress.postal_code}
                        onChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Country"
                        name="country"
                        value={newAddress.country}
                        onChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={newAddress.isPrimary}
                            onChange={handleAddressChange}
                            name="isPrimary"
                          />
                        }
                        label="Set as Default Address"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                  <Button onClick={() => setShowAddressForm(false)}>Cancel</Button>
                  <Button 
                    variant="contained" 
                    onClick={handleAddressSave}
                  >
                    {editingAddressId ? 'Update' : 'Save'} Address
                  </Button>
                </CardActions>
              </Card>
            )}

            <Grid container spacing={2}>
              {addresses.map((address) => (
                <Grid item xs={12} key={address.id}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" component="div">
                          {address.address_type}
                          {address.isPrimary && (
                            <Typography 
                              component="span" 
                              color="primary"
                              sx={{ ml: 1, fontSize: '0.8rem' }}
                            >
                              (Default)
                            </Typography>
                          )}
                        </Typography>
                        <Box>
                          <IconButton 
                            onClick={() => handleEditAddress(address)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton 
                            onClick={() => handleDeleteAddress(address.id)}
                            size="small"
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <Typography color="text.secondary">
                        {address.address_line1}
                        {address.address_line2 && <>, {address.address_line2}</>}<br />
                        {address.city}, {address.state} {address.postal_code}<br />
                        {address.country}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {!address.isPrimary && (
                        <Button 
                          size="small" 
                          onClick={() => handleSetPrimary(address.id)}
                        >
                          Set as Default
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Container>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );}
export default MerchantSettings;