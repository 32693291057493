import React, { useState } from "react";
import {
  ArrowUpIcon,
  BellIcon,
  ChartBarIcon,
  UserIcon,
  CreditCardIcon,
  ShoppingBagIcon,
  ExternalLinkIcon,
  HomeIcon
} from "@heroicons/react/solid";
import { useNavigate, useLocation } from 'react-router-dom';

const AdminSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = useState(null);

  const menuItems = [
    { icon: ChartBarIcon, path: '/adminpanel', label: 'Dashboard' },
    { icon: UserIcon, path: '/adminuserlist', label: 'Users' },
    { icon: CreditCardIcon, path: '/disputes', label: 'Disputes' },
    { icon: ShoppingBagIcon, path: '/all-orders', label: 'Orders' },
    { icon: BellIcon, path: '/adminpanel', label: 'Notifications' },
  ];

  const isActivePath = (path) => location.pathname === path;

  return (
    <div className="bg-slate-800 flex-none w-14 sm:w-20 h-screen">
      {/* Logo Section */}
      <div 
        className="h-20 items-center flex cursor-pointer relative group"
        onClick={() => navigate('/')}
      >
        <HomeIcon 
          width={40} 
          className="text-gray-300 left-3 sm:left-6 fixed transition-all duration-200 transform group-hover:text-white group-hover:scale-110" 
        />
        <div className="fixed left-16 sm:left-24 bg-gray-900 text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
          Home
        </div>
      </div>

      {/* Main Menu */}
      <div className="fixed left-3 sm:left-6 top-[200px] space-y-4">
        {menuItems.map((item, index) => {
          const Icon = item.icon;
          return (
            <div 
              key={index}
              className="relative group"
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Icon
                width={40}
                className={`p-2 rounded-lg cursor-pointer transition-all duration-200 transform
                  ${isActivePath(item.path) 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-600 text-gray-300 hover:bg-gray-500 hover:text-white'}
                  ${hoveredItem === index ? 'scale-110' : ''}
                `}
                onClick={() => navigate(item.path)}
              />
              <div 
                className={`fixed left-16 sm:left-24 bg-gray-900 text-white px-2 py-1 rounded 
                  transition-opacity duration-200 whitespace-nowrap
                  ${hoveredItem === index ? 'opacity-100' : 'opacity-0'}`}
              >
                {item.label}
              </div>
            </div>
          );
        })}
      </div>

      {/* Bottom Actions */}
      <div className="fixed bottom-4 left-3 sm:left-6 space-y-4">
        <div className="relative group">
          <a href="#top" className="block">
            <ArrowUpIcon
              width={40}
              className="bg-gray-600 p-2 rounded-lg text-gray-300 transition-all duration-200 
                hover:bg-gray-500 hover:text-white hover:scale-110"
            />
          </a>
          <div className="fixed left-16 sm:left-24 bg-gray-900 text-white px-2 py-1 rounded 
            opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
            Scroll to Top
          </div>
        </div>

        <div className="relative group">
          <ExternalLinkIcon
            width={40}
            className="bg-gray-600 p-2 rounded-lg text-gray-300 cursor-pointer transition-all duration-200 
              hover:bg-gray-500 hover:text-white hover:scale-110"
            onClick={() => window.open('https://dashboard.stripe.com', '_blank')}
          />
          <div className="fixed left-16 sm:left-24 bg-gray-900 text-white px-2 py-1 rounded 
            opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
            Open Stripe Dashboard
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;