import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaCheckCircle, FaShippingFast, FaBoxOpen } from 'react-icons/fa';
import UserSidebar from '../sidebar/UserSidebar'; // Adjust the path as necessary
import Navbar from '../navbar/Navbar'; 
import { Container, Typography, Paper, Box, CircularProgress, Alert } from '@mui/material';

const Tracking = () => {
  const { trackingNumber, orderId } = useParams();
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (trackingNumber) {
      getTrackingInfo();
    }
  }, [trackingNumber]);

  const getTrackingInfo = async () => {
    setLoading(true); // Start loading
    setError(null); // Clear previous errors
    try {
      const trackingResponse = await axios.post('https://staging-backend.mangodesert-784b53bb.uaenorth.azurecontainerapps.io/fedex/track', { trackingNumber, orderId });
      setTrackingInfo(trackingResponse.data.output.completeTrackResults[0].trackResults[0]);
      setError(null);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch tracking information.');
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <Box display="flex">
      <UserSidebar sx={{ width: '240px' }} /> {/* Adjust sidebar width here */}
      <Box flexGrow={3.5}>
        <Navbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Tracking Information
            </Typography>
            {loading && <CircularProgress sx={{ mt: 2 }} />} {/* Display loader */}
            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
            {trackingInfo && (
              <Box mt={4}>
                <Typography variant="h6">Tracking Number: {trackingInfo.trackingNumberInfo.trackingNumber}</Typography>
                <Typography variant="subtitle1" color="textSecondary">Status: {trackingInfo.latestStatusDetail.statusByLocale}</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <FaShippingFast size={24} color="green" />
                  <Typography ml={2} variant="body1">Shipped from {trackingInfo.shipperInformation.address.city}, {trackingInfo.shipperInformation.address.stateOrProvinceCode}</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <FaBoxOpen size={24} color="blue" />
                  <Typography ml={2} variant="body1">Arrived at {trackingInfo.recipientInformation.address.city}, {trackingInfo.recipientInformation.address.stateOrProvinceCode}</Typography>
                </Box>

                {/* Removing the dateAndTimes map to clean up the interface */}
              </Box>
            )}
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default Tracking;