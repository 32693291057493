import React, { useEffect, useState } from "react";
import CardItem from "./CardItem";
import api from '../../../services/api';

const KPICards = () => {
  // Initialize state with 0
  const [paymentsReceived, setPaymentsReceived] = useState(0);
  const [paymentsCleared, setPaymentsCleared] = useState(0);
  const [profit, setProfit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [ordersResponse, transactionsResponse] = await Promise.all([
          api.get('/orders/total-ordersamount'),
          api.get('/transactions/total-amountcleared')
        ]);

        const totalOrders = ordersResponse.data.totalAmount || 0;
        const totalCleared = transactionsResponse.data.totalClearedAmount || 0;

        setPaymentsReceived(totalOrders);
        setPaymentsCleared(totalCleared);
        setProfit(totalOrders - totalCleared);
        setError(null);
      } catch (error) {
        console.error('Error fetching KPI data:', error);
        setError('Failed to load KPI data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Format currency helper function
  const formatCurrency = (value) => {
    try {
      return `AED ${(Number(value) || 0).toLocaleString()}`;
    } catch (error) {
      console.error('Error formatting currency:', error);
      return 'AED 0';
    }
  };

  // Calculate percentage change (placeholder values for now)
  const getPercentageChange = (currentValue, previousValue = 0) => {
    if (!previousValue) return '+0%';
    const change = ((currentValue - previousValue) / previousValue) * 100;
    return change >= 0 ? `+${change.toFixed(1)}%` : `${change.toFixed(1)}%`;
  };

  const kpis = [
    {
      title: "Payments Received",
      deltaType: "moderateIncrease",
      deltaValue: getPercentageChange(paymentsReceived),
      metricValue: formatCurrency(paymentsReceived),
    },
    {
      title: "Payments Cleared",
      deltaType: "increase",
      deltaValue: getPercentageChange(paymentsCleared),
      metricValue: formatCurrency(paymentsCleared),
    },
    {
      title: "Profit",
      deltaType: profit >= 0 ? "increase" : "decrease",
      deltaValue: getPercentageChange(profit),
      metricValue: formatCurrency(profit),
    },
  ];

  if (loading) {
    return (
      <div className="flex flex-col lg:flex-row gap-2 w-full">
        <div className="animate-pulse flex space-x-4">
          {[1, 2, 3].map((index) => (
            <div key={index} className="bg-gray-200 h-32 w-64 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col lg:flex-row gap-2 w-full">
        <div className="text-red-500 p-4 rounded-lg bg-red-50">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row gap-2 w-full">
      {kpis.map((kpi, index) => (
        <CardItem
          key={index}
          title={kpi.title}
          deltaType={kpi.deltaType}
          deltaValue={kpi.deltaValue}
          metricValue={kpi.metricValue}
        />
      ))}
    </div>
  );
};

export default KPICards;