// components/UserSidebar/index.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';
import {
  Dashboard as DashboardIcon,
  LocalShipping as LocalShippingIcon,
  CreditCard as CreditCardIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  ShoppingCart as ShoppingCartIcon
} from '@mui/icons-material';
import logo from '../../pages/panel/images/pay-with-3s.png';

const UserSidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const trigger = useRef(null);
  const sidebar = useRef(null);
  const { logout } = useAuth();

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

  // Close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // Close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
        onClick={() => setSidebarOpen(false)}
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 
        h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 
        bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Modified Sidebar header */}
        <div className="flex justify-between items-center mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(false)}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Modified Logo container */}
          <NavLink 
            to="/" 
            className="flex items-center justify-center min-w-[40px] lg:min-w-0 lg:w-10 lg:sidebar-expanded:w-16 2xl:w-16 transition-all duration-200"
          >
            <img 
              src={logo} 
              alt="3S Logo" 
              className="w-auto h-auto max-h-[60px] lg:max-h-[60px] lg:sidebar-expanded:max-h-[60px] 2xl:max-h-[60px] object-contain transition-all duration-200"
            />
          </NavLink>
        </div>


        {/* Links */}
        <div className="space-y-8">
          <div>
            <h3 className="text-sm uppercase text-slate-500 font-semibold pl-3">
              <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Main</span>
            </h3>
            <ul className="mt-3">
              {/* Dashboard */}
              <li className={`px-3 py-3 rounded-sm mb-0.5 last:mb-0 ${pathname === '/panel' && 'bg-slate-900'}`}>
                <NavLink
                  to="/panel"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/panel' && 'hover:text-slate-200'
                  }`}
                >
                  <div className="flex items-center">
                    <DashboardIcon className="shrink-0 h-7 w-7" />
                    <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Dashboard
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Track Order */}
              <li className={`px-3 py-3 rounded-sm mb-0.5 last:mb-0 ${pathname === '/user-orders' && 'bg-slate-900'}`}>
                <NavLink
                  to="/user-orders"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/user-orders' && 'hover:text-slate-200'
                  }`}
                >
                  <div className="flex items-center">
                    <LocalShippingIcon className="shrink-0 h-7 w-7" />
                    <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      All Orders
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Checkout */}
              <li className={`px-3 py-3 rounded-sm mb-0.5 last:mb-0 ${pathname === '/checkout' && 'bg-slate-900'}`}>
  <NavLink
    to="/checkout"
    className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
      pathname === '/checkout' && 'hover:text-slate-200'
    }`}
  >
    <div className="flex items-center">
      <ShoppingCartIcon className="shrink-0 h-7 w-7" />
      <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
        Checkout
      </span>
    </div>
  </NavLink>
</li>

              {/* Profile */}
              <li className={`px-3 py-3 rounded-sm mb-0.5 last:mb-0 ${pathname === '/user-profile' && 'bg-slate-900'}`}>
                <NavLink
                  to="/user-profile"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/user-profile' && 'hover:text-slate-200'
                  }`}
                >
                  <div className="flex items-center">
                    <PersonIcon className="shrink-0 h-7 w-7" />
                    <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Profile
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Settings */}
             {/* <li className={`px-3 py-3 rounded-sm mb-0.5 last:mb-0 ${pathname === '/settings' && 'bg-slate-900'}`}>
                <NavLink
                  to="/settings"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === '/settings' && 'hover:text-slate-200'
                  }`}
                >
                  <div className="flex items-center">
                    <SettingsIcon className="shrink-0 h-7 w-7" />
                    <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Settings
                    </span>
                  </div>
                </NavLink>
              </li> */}

              {/* Logout */}
              <li className="px-3 py-3 rounded-sm mb-0.5 last:mb-0">
                <button
                  onClick={logout}
                  className="w-full flex items-center text-slate-200 hover:text-white truncate transition duration-150"
                >
                  <ExitToAppIcon className="shrink-0 h-7 w-7" />
                  <span className="text-base font-medium ml-4 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                    Logout
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;