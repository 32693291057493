import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./global.css";
import { ThemeProvider } from '@mui/material/styles';
import { ColorModeContext, useMode } from './theme'; // Adjust the import based on your file structure
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary component

const Root = () => {
  const [theme, colorMode] = useMode();

  return (
    <ThemeProvider theme={theme}>
      {/* <ErrorBoundary> */}
        <App />
      {/* </ErrorBoundary> */}
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
