import React from 'react';
import buttonSVG from './buttonSVG.svg';

const BlockExpressCheckoutWal = () => {
    return (
        <a href="https://shep-poc-client.vercel.app/checkout" target="_blank" rel="noopener noreferrer">
            <img src={buttonSVG} alt="Pay with 3S" style={{ cursor: 'pointer' }} />
        </a>
    );
};

export default BlockExpressCheckoutWal;