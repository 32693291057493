// components/Widget/Widget.jsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import api from '../../../services/api';
import {
  PersonOutlined as PersonOutlinedIcon,
  ShoppingCart as ShoppingCartOutlinedIcon,
  MonetizationOn as MonetizationOnOutlinedIcon,
  AccountBalanceWallet as AccountBalanceWalletOutlinedIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Widget = ({ type }) => {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [percentageChange, setPercentageChange] = useState(0);
  const { getUser } = useAuth();
  const userData = getUser();

  useEffect(() => {
    const fetchData = async () => {
      if (!userData?.id) return;

      try {
        let response;
        switch (type) {
          case 'pending':
            response = await api.get(`/users/${userData.id}/orders/pending`);
            setAmount(response.data.length || 0);
            break;
            
          case 'order':
            response = await api.get(`/users/${userData.id}/orders/count`);
            setAmount(response.data.totalOrders || 0);
            break;
            
          case 'earning':
            response = await api.get(`/users/${userData.id}/orders/total-spent`);
            setAmount(response.data.totalSpent || 0);
            break;
            
          case 'past':
            response = await api.get(`/users/${userData.id}/orders/past`);
            setAmount(response.data.length || 0);
            break;
            
          default:
            break;
        }

        // Calculate percentage change if available
        if (response?.data?.previousPeriod) {
          const change = ((response.data.currentPeriod - response.data.previousPeriod) / response.data.previousPeriod) * 100;
          setPercentageChange(change.toFixed(1));
        }

      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type, userData?.id]);

  const widgetConfigs = {
    pending: {
      title: "PENDING ORDERS",
      isMoney: false,
      link: "/user-orders",
      icon: (
        <span className="flex justify-center items-center w-12 h-12 bg-rose-100 dark:bg-rose-500/20 rounded-full">
          <PersonOutlinedIcon className="text-rose-500" />
        </span>
      ),
      linkText: "View pending orders",
      color: "text-rose-500"
    },
    order: {
      title: "TOTAL ORDERS",
      isMoney: false,
      link: "/user-orders",
      icon: (
        <span className="flex justify-center items-center w-12 h-12 bg-emerald-100 dark:bg-emerald-500/20 rounded-full">
          <ShoppingCartOutlinedIcon className="text-emerald-500" />
        </span>
      ),
      linkText: "View all orders",
      color: "text-emerald-500"
    },
    earning: {
      title: "TOTAL SPENT",
      isMoney: true,
      link: "/user-orders",
      icon: (
        <span className="flex justify-center items-center w-12 h-12 bg-blue-100 dark:bg-blue-500/20 rounded-full">
          <MonetizationOnOutlinedIcon className="text-blue-500" />
        </span>
      ),
      linkText: "View spending details",
      color: "text-blue-500"
    },
    past: {
      title: "PAST ORDERS",
      isMoney: false,
      link: "/user-orders",
      icon: (
        <span className="flex justify-center items-center w-12 h-12 bg-purple-100 dark:bg-purple-500/20 rounded-full">
          <AccountBalanceWalletOutlinedIcon className="text-purple-500" />
        </span>
      ),
      linkText: "View order history",
      color: "text-purple-500"
    }
  }[type] || {};

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <div className="px-5 pt-5 pb-4">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          {widgetConfigs.icon}
        </header>
        <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-100 mb-2">
          {widgetConfigs.title}
        </h2>
  
        {loading ? (
          <div className="animate-pulse">
            <div className="h-9 bg-slate-200 dark:bg-slate-700 rounded w-24 mb-4"></div>
          </div>
        ) : (
          <>
            <div className="flex items-start">
              <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">
                {widgetConfigs.isMoney ? `AED ${amount.toLocaleString()}` : amount.toLocaleString()}
              </div>
              {percentageChange !== 0 && (
                <div className={`text-sm font-semibold ${percentageChange >= 0 ? 'text-green-500' : 'text-rose-500'} px-1.5 rounded-full`}>
                  {percentageChange > 0 && '+'}{percentageChange}%
                </div>
              )}
            </div>
            <div className="flex items-center justify-between mt-4">
              <Link 
                className={`text-sm font-medium ${widgetConfigs.color} hover:opacity-75`} 
                to={widgetConfigs.link}
              >
                {widgetConfigs.linkText} →
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Widget;