import React, { useState } from "react";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Avatar,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import LicenseVerification from "./LicenseVerification";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../components/AuthProvider"; // Adjust path as needed

// Password validation helper functions
const passwordChecks = {
  length: (password) => password.length >= 8,
  uppercase: (password) => /[A-Z]/.test(password),
  lowercase: (password) => /[a-z]/.test(password),
  number: (password) => /[0-9]/.test(password),
  special: (password) => /[!@#$%^&*]/.test(password),
};

// Check if all password requirements are met
const allRequirementsMet = (password) => {
  return Object.values(passwordChecks).every((check) => check(password));
};

// Password Tooltip Component
function PasswordTooltip({ password, show }) {
  return (
    <div
      style={{
        position: "absolute",
        top: "calc(100% + 5px)",
        left: "0",
        zIndex: "1000",
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        border: "1px solid #ddd",
        width: "100%",
        display: show ? "block" : "none",
      }}
    >
      <div>
        <div
          style={{
            color: passwordChecks.length(password) ? "green" : "#666",
            marginBottom: "4px",
          }}
        >
          {passwordChecks.length(password) ? "✓" : "○"} At least 8 characters
        </div>
        <div
          style={{
            color: passwordChecks.uppercase(password) ? "green" : "#666",
            marginBottom: "4px",
          }}
        >
          {passwordChecks.uppercase(password) ? "✓" : "○"} One uppercase letter
        </div>
        <div
          style={{
            color: passwordChecks.lowercase(password) ? "green" : "#666",
            marginBottom: "4px",
          }}
        >
          {passwordChecks.lowercase(password) ? "✓" : "○"} One lowercase letter
        </div>
        <div
          style={{
            color: passwordChecks.number(password) ? "green" : "#666",
            marginBottom: "4px",
          }}
        >
          {passwordChecks.number(password) ? "✓" : "○"} One number
        </div>
        <div
          style={{ color: passwordChecks.special(password) ? "green" : "#666" }}
        >
          {passwordChecks.special(password) ? "✓" : "○"} One special character
          (!@#$%^&*)
        </div>
      </div>
    </div>
  );
}

function OTPDialog({ open, email, onClose, onVerify }) {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isResending, setIsResending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleVerify = async () => {
    try {
      setIsVerifying(true);
      const response = await api.post("/auth/verify-otp", {
        email,
        otp,
      });

      if (response.data.success) {
        await onVerify(); // Wait for verification to complete
        // Don't need onClose() here as it's handled in handleVerificationSuccess
      } else {
        setError("Invalid OTP code");
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Verification failed. Please try again."
      );
    } finally {
      setIsVerifying(false);
    }
  };

  // Prevent dialog from closing if clicked outside
  const handleClose = (event, reason) => {
    if (isVerifying) return; // Don't close if verification is in progress
    if (reason === "backdropClick") return; // Don't close on backdrop click
    onClose();
  };

  const handleResendOTP = async () => {
    try {
      setIsResending(true);
      await api.post("/auth/send-otp", { email });
      setCountdown(60);
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      setError("Failed to resend OTP");
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={isVerifying}
    >
      <DialogTitle>Verify your email</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Enter the verification code sent to {email}
        </Typography>
        <TextField
          autoFocus
          fullWidth
          label="Verification Code"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          error={!!error}
          helperText={error}
          margin="normal"
          disabled={isVerifying}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2, flexDirection: "column", gap: 1 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleResendOTP}
            disabled={isResending || countdown > 0 || isVerifying}
            color="secondary"
          >
            {countdown > 0 ? `Resend in ${countdown}s` : "Resend Code"}
          </Button>
          <Box>
            <Button onClick={onClose} sx={{ mr: 1 }} disabled={isVerifying}>
              Cancel
            </Button>
            <Button
              onClick={handleVerify}
              variant="contained"
              disabled={isVerifying}
            >
              {isVerifying ? <CircularProgress size={24} /> : "Verify"}
            </Button>
          </Box>
        </Box>
        {error && (
          <Typography
            color="error"
            variant="caption"
            sx={{ width: "100%", textAlign: "center" }}
          >
            {error}
          </Typography>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default function Registration() {
  const navigate = useNavigate();
  const { register } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);

  const [passwordFocused, setPasswordFocused] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    userType: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!allRequirementsMet(formData.password)) {
        toast.error("Password does not meet requirements");
        return;
      }

      setLoading(true);

      const emailCheckResponse = await api.post("/auth/check-email", {
        email: formData.email,
      });

      if (!emailCheckResponse.data.available) {
        toast.error("This email address is already registered");
        return;
      }

      await api.post("/auth/send-otp", { email: formData.email });
      setOtpDialogOpen(true);
      toast.success("Verification code sent to your email");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage?.includes("username")) {
        toast.error("Username is already taken");
      } else if (errorMessage?.includes("password")) {
        toast.error("Invalid password format");
      } else {
        toast.error(errorMessage || "Registration failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSuccess = async () => {
    try {
      await register({
        username: formData.username,
        password: formData.password,
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        userType: formData.userType,
      });
      setOtpDialogOpen(false);
      toast.success("Registration successful!");
      navigate("/login", { replace: true });
    } catch (error) {
      toast.error(error.response?.data?.message || "Registration failed");
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#08C989" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          Sign up
        </Typography>

        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name="firstName"
                    label="First Name"
                    required
                    fullWidth
                    size="small"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    required
                    fullWidth
                    size="small"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="username"
                    label="Username"
                    required
                    fullWidth
                    size="small"
                    value={formData.username}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="Email"
                    required
                    fullWidth
                    size="small"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{ position: "relative" }}>
                  <TextField
                    name="password"
                    label="Password"
                    required
                    fullWidth
                    size="small"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    onFocus={() => setPasswordFocused(true)}
                    onBlur={() => setPasswordFocused(false)}
                    error={
                      formData.password &&
                      !allRequirementsMet(formData.password)
                    }
                    helperText={
                      formData.password &&
                      !allRequirementsMet(formData.password)
                        ? "Password doesn't meet requirements"
                        : ""
                    }
                  />
                  <PasswordTooltip
                    password={formData.password}
                    show={
                      passwordFocused && !allRequirementsMet(formData.password)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>User Type *</InputLabel>
                  <Select
                    name="userType"
                    value={formData.userType}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="business">Business</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    sx={{
                      bgcolor: "#08C989",
                      "&:hover": { bgcolor: "#07b37b" },
                      mt: 1,
                    }}
                  >
                    {loading ? <CircularProgress size={24} /> : "Sign Up"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>

        <OTPDialog
          open={otpDialogOpen}
          email={formData.email}
          onClose={() => setOtpDialogOpen(false)}
          onVerify={handleVerificationSuccess}
        />
      </Box>
    </Container>
  );
}
