import React, { useState, useEffect } from 'react';
import { CircularProgress, Alert, TextField, Button, Grid, Typography, Container, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
import api from "../../services/api";
import { useAuth } from '../AuthProvider';

function CreatePickupRequestForm() {
    const { getUser } = useAuth();
    const userData = getUser();
    const userId = userData?.id;
    const personName = userData?.name;
    const [formData, setFormData] = useState({
        associatedAccountNumberType: 'FEDEX_GROUND',
        totalWeight: '30',
        packageCount: '5',
        carrierCode: 'FDXG',
        remarks: 'None',
        countryRelationships: 'DOMESTIC',
        pickupType: 'REGULAR_STOP',
        residential: false,
        readyDateTimestamp: '',
        customerCloseTime: '18:00:00',
        pickupDateType: 'SAME_DAY',
        packageLocation: 'FRONT',
        earlyPickup: false,
        suppliesRequested: 'None',
        oversizePackageCount: '0',
        emailDetails: '',
        locale: 'en_US',
        format: 'HTML',
        userMessage: 'None',
        companyName: '',
        personName: '',
        phoneNumber: '',
        streetLines: '',
        city: '',
        stateOrProvinceCode: 'DU',
        postalCode: '',
        countryCode: 'US',
        pickupAddressType: 'ACCOUNT'  // New field with default value
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        

        if (!userId) {
            setError('User ID is undefined');
            setLoading(false);
            return;
        }

        const fetchOrganizationDetails = async () => {
            try {
                // Fetch merchant, organization, and primary address details in parallel
                const [merchantResponse, primaryAddressResponse] = await Promise.all([
                    api.get(`/business/merchant/${userId}`),
                    api.get(`/org-address/${userId}/primary-address`)
                ]);

                const merchantData = merchantResponse.data.data;
                const primaryAddress = primaryAddressResponse.data;
        
                if (!primaryAddress) {
                    throw new Error('No primary address found. Please set a primary address first.');
                }
        
                setFormData(prevState => ({
                    ...prevState,
                    // Merchant details
                    personName: personName || merchantData.contact_name,
                    phoneNumber: merchantData.phone_number,
                    emailDetails: merchantData.email,
        
                    // Organization details from merchant response
                    companyName: merchantData.business_name,
                    
                    // Primary Address details
                    streetLines: primaryAddress.address_line1,
                    city: primaryAddress.city,
                    stateOrProvinceCode: primaryAddress.state,
                    postalCode: primaryAddress.postal_code,
                    countryCode: primaryAddress.country
                }));
            } catch (error) {
                console.error('Error fetching details:', error);
                if (error.message.includes('primary address')) {
                    setError(error.message);
                } else {
                    setError('Failed to load organization details. Please try again later.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizationDetails();
    }, []);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const [responseMessage, setResponseMessage] = useState(null); // State for the response message
    const [responseType, setResponseType] = useState(null); // State for the response type
    const [loadingAvailability, setLoadingAvailability] = useState(false);
    const [loadingPickup, setLoadingPickup] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingAvailability(true);
        setResponseMessage(null);  // Clear any existing message before making the API call;  // Start loading

        
        const merchant_id = userData?.id;

        if (!merchant_id) {
            setResponseMessage('Merchant ID is missing. Please log in again.');
            setResponseType('error');
            setLoadingPickup(false);
            return;
        }
        // Include merchant_id in the formData
        const updatedFormData = {
            ...formData,
            merchant_id: userData.id
        };

        try {
            const response = await api.post('/fedex/create-pickup', updatedFormData);
            if (response.data.success) {
                const confirmationCode = response.data.data?.output?.pickupConfirmationCode;
                setResponseMessage(`Pickup created successfully! Confirmation Code: ${confirmationCode || "N/A"}`);
                setResponseType('success');
            } else if (response.data.success === 'false') {
                // Extracting the first error message from the API response
                const errorMessage = response.data.data?.error?.errors?.[0]?.message || "Try picking another day/time.";
                setResponseMessage(`Failed to create pickup: ${response.data.message}. Error: ${errorMessage}`);
                setResponseType('error');
            }
        } catch (error) {
            setResponseMessage(`An error occurred: ${error.message}`);
            setResponseType('error');
        } finally {
            setLoadingAvailability(false);;  // Stop loading
        }
    };

    const handleCheckAvailability = async (e) => {
        e.preventDefault();
        setLoadingPickup(true);  // Start loading
        setResponseMessage(null);  // Clear any existing message before making the API call
        try {
            const response = await api.post('/fedex/check-pickup-availability', formData);
            if (response.data.output?.options?.length > 0) {
                const option = response.data.output.options[0];
                setResponseMessage(
                    `Availability Check Successful! \n
                    Carrier: ${option.carrier}\n
                    Available: ${option.available ? "Yes" : "No"}\n
                    Pickup Date: ${option.pickupDate}\n
                    Cut-Off Time: ${option.cutOffTime}\n
                    Ready Time Options: ${option.readyTimeOptions.join(", ")}\n
                    Latest Time Options: ${option.latestTimeOptions.join(", ")}\n`
                );
                setResponseType('success');
            } else {
                // Handle the case where no options are available
                setResponseMessage('No availability options found.');
                setResponseType('warning');
            }
        } catch (error) {
            setResponseMessage(`An error occurred: ${error.message}`);
            setResponseType('error');
        } finally {
            setLoadingPickup(false);  // Stop loading
        }
    };


    return (
        <Container maxWidth="md">
            <Typography variant="h4" gutterBottom>
            </Typography>
            
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Company Name"
                            variant="outlined"
                            fullWidth
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Person Name"
                            variant="outlined"
                            fullWidth
                            name="personName"
                            value={formData.personName}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Account Number Type</InputLabel>
                            <Select
                                name="associatedAccountNumberType"
                                value={formData.associatedAccountNumberType}
                                onChange={handleChange}
                                label="Account Number Type"
                                required
                            >
                                <MenuItem value="FEDEX_EXPRESS">FedEx Express</MenuItem>
                                <MenuItem value="FEDEX_GROUND">FedEx Ground</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Pickup Address Type</InputLabel>
                            <Select
                                name="pickupAddressType"
                                value={formData.pickupAddressType}
                                onChange={handleChange}
                                label="Pickup Address Type"
                                required
                            >
                                <MenuItem value="ACCOUNT">Account</MenuItem>
                                <MenuItem value="SHIPPER">Shipper</MenuItem>
                                <MenuItem value="OTHER">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Street Address"
                            variant="outlined"
                            fullWidth
                            name="streetLines"
                            value={formData.streetLines}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="City"
                            variant="outlined"
                            fullWidth
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>State/Province Code</InputLabel>
                            <Select
                                name="stateOrProvinceCode"
                                value={formData.stateOrProvinceCode}
                                onChange={handleChange}
                                label="State/Province Code"
                                required
                            >
                                <MenuItem value="AJ">AJ</MenuItem>
                                <MenuItem value="AZ">AZ</MenuItem>
                                <MenuItem value="DU">DU</MenuItem>
                                <MenuItem value="FU">FU</MenuItem>
                                <MenuItem value="SH">SH</MenuItem>
                                <MenuItem value="RK">RK</MenuItem>
                                <MenuItem value="UQ">UQ</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Postal Code"
                            variant="outlined"
                            fullWidth
                            name="postalCode"
                            value={formData.postalCode}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Total Weight (LB)"
                            variant="outlined"
                            fullWidth
                            name="totalWeight"
                            value={formData.totalWeight}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Package Count"
                            variant="outlined"
                            fullWidth
                            name="packageCount"
                            value={formData.packageCount}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Carrier Code</InputLabel>
                            <Select
                                name="carrierCode"
                                value={formData.carrierCode}
                                onChange={handleChange}
                                label="Carrier Code"
                                required
                            >
                                <MenuItem value="FDXE">FDXE - FedEx Express</MenuItem>
                                <MenuItem value="FDXG">FDXG - FedEx Ground</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            name="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                            helperText="Maximum length is 60 characters."
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Country Relationships</InputLabel>
                            <Select
                                name="countryRelationships"
                                value={formData.countryRelationships}
                                onChange={handleChange}
                                label="Country Relationships"
                            >
                                <MenuItem value="DOMESTIC">Domestic</MenuItem>
                                <MenuItem value="INTERNATIONAL">International</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Pickup Type</InputLabel>
                            <Select
                                name="pickupType"
                                value={formData.pickupType}
                                onChange={handleChange}
                                label="Pickup Type"
                                required
                            >
                                <MenuItem value="ON_CALL">On Call</MenuItem>
                                <MenuItem value="PACKAGE_RETURN_PROGRAM">Package Return Program</MenuItem>
                                <MenuItem value="REGULAR_STOP">Regular Stop</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Ready Date Timestamp"
                            type="datetime-local"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="readyDateTimestamp"
                            value={formData.readyDateTimestamp}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Customer Close Time"
                            variant="outlined"
                            fullWidth
                            name="customerCloseTime"
                            value={formData.customerCloseTime}
                            onChange={handleChange}
                            helperText="Specify the latest time the driver gets access to pick up the package. The time format is in HH:MM:SS and it refers to the local timezone. The driver should pick up the package by the given pickup time.
Example: 18:00:00"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.earlyPickup}
                                    onChange={handleChange}
                                    name="earlyPickup"
                                />
                            }
                            label="Early Pickup"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Supplies Requested"
                            variant="outlined"
                            fullWidth
                            name="suppliesRequested"
                            value={formData.suppliesRequested}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Oversize Package Count"
                            variant="outlined"
                            fullWidth
                            name="oversizePackageCount"
                            value={formData.oversizePackageCount}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Notification Email"
                            variant="outlined"
                            fullWidth
                            name="emailDetails"
                            value={formData.emailDetails}
                            onChange={handleChange}
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField
                            label="User Message"
                            variant="outlined"
                            fullWidth
                            name="userMessage"
                            value={formData.userMessage}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <Grid container spacing={2}>
        <Grid item xs={6}>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCheckAvailability}
                disabled={loadingPickup}
            >
                {loadingPickup ? <CircularProgress size={24} /> : "Check Service Availability"}
            </Button>
        </Grid>
        <Grid item xs={6}>
            <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                onClick={handleSubmit}
                disabled={loadingAvailability}
            >
                {loadingAvailability ? <CircularProgress size={24} /> : "Create Pickup"}
            </Button>
        </Grid>
    </Grid>
                    </Grid>
                </Grid>
            </form>
            {responseMessage && (
                <Alert severity={responseType} sx={{ marginTop: '20px' }}>
                    {responseMessage}
                </Alert>
            )}
        </Container>
    );
}

export default CreatePickupRequestForm;