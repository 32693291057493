// components/Header/index.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';
import { toast } from 'react-toastify';
import {
  Search as SearchIcon,
  Language as LanguageIcon,
  Fullscreen as FullscreenIcon
} from '@mui/icons-material';
import SearchModal from '../../pages/panel/usercomps/ModalSearch';
import Notifications from '../../pages/panel/usercomps/DropdownNotifications';
import Help from '../../pages/panel/usercomps/DropdownHelp';
import UserMenu from '../../pages/panel/usercomps/DropdownProfile';
import ThemeToggle from '../../pages/panel/usercomps/ThemeToggle';

const Navbar = ({ sidebarOpen, setSidebarOpen }) => {
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.clear();
      toast.info("You have been logged out.");
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <header className="sticky top-0 z-30 bg-white border-b border-slate-200">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Left side */}
          <div className="flex items-center">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>

            {/* Search */}
            <div className="relative ml-4">
              <button
                className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ${searchModalOpen && 'bg-slate-200'}`}
                onClick={(e) => { e.stopPropagation(); setSearchModalOpen(true); }}
                aria-controls="search-modal"
              >
                <span className="sr-only">Search</span>
                <SearchIcon className="w-4 h-4 text-slate-500 dark:text-slate-400" />
              </button>
              <SearchModal 
                id="search-modal" 
                searchId="search" 
                modalOpen={searchModalOpen} 
                setModalOpen={setSearchModalOpen} 
              />
            </div>
          </div>

          {/* Right side */}
          <div className="flex items-center space-x-3">
            

            <Notifications align="right" />
            <Help align="right" />
            <ThemeToggle />

            {/* Divider */}
            <hr className="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />

            {/* User menu */}
            <UserMenu align="right" logout={handleLogout} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;