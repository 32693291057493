import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
  Button,
  Flex,
} from "@tremor/react";
import { 
  TruckIcon,
} from "@heroicons/react/solid";
import api from "../../../services/api";

const AllOrdersTable = () => {
  const [deliveringOrderId, setDeliveringOrderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    totalPages: 1
  });

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await api.get("/orders/");
      // Since your API returns an array directly
      if (Array.isArray(response.data)) {
        setOrders(response.data);
        setPagination({
          total: response.data.length,
          currentPage: 1,
          totalPages: 1
        });
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };
  const getStatusColor = (status) => {
    const statusColors = {
      'pending': 'yellow',
      'processing': 'blue',
      'shipped': 'green',
      'delivered': 'gray',
      'cancelled': 'red'
    };
    return statusColors[status] || 'gray';
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleDeliverOrder = async (orderId) => {
    try {
      setDeliveringOrderId(orderId); // Set loading state for this specific order
      await api.post(`/orders/${orderId}/deliver`);
      await fetchOrders();
    } catch (error) {
      console.error("Error delivering order:", error);
      alert('Failed to deliver order. Please try again.');
    } finally {
      setDeliveringOrderId(null); // Clear loading state
    }
  };


  if (loading) {
    return <div className="flex justify-center p-4">Loading orders...</div>;
  }

  return (
    <Card className="mt-4">
      <Flex className="justify-between">
        <Title>All Orders</Title>
        <Text className="text-gray-500">
          Total Value: {formatCurrency(orders.reduce((sum, o) => sum + Number(o.amount), 0))}
        </Text>
      </Flex>
      
      <Table className="mt-5">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Order ID</TableHeaderCell>
            <TableHeaderCell>User ID</TableHeaderCell>
            <TableHeaderCell>Merchant ID</TableHeaderCell>
            <TableHeaderCell>Amount</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Tracking Number</TableHeaderCell>
            <TableHeaderCell>Created At</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {orders && orders.length > 0 ? (  // Simplified check since we know it's an array
    orders.map((order) => (
              <TableRow key={order.order_id}>
                <TableCell>
                  <Text>{order.order_id}</Text>
                </TableCell>
                <TableCell>
                  <Text>{order.user_id}</Text>
                </TableCell>
                <TableCell>
                  <Text>{order.merchant_id}</Text>
                </TableCell>
                <TableCell>
                  <Text>{formatCurrency(order.amount)}</Text>
                </TableCell>
                <TableCell>
                  <Badge color={getStatusColor(order.status)}>
                    {order.status?.toUpperCase()}
                  </Badge>
                </TableCell>
                <TableCell>
                  <Text>{order.tracking_number || 'N/A'}</Text>
                </TableCell>
                <TableCell>
                  <Text>{formatDate(order.createdAt)}</Text>
                </TableCell>
                <TableCell>
                <Button
    size="xs"
    icon={TruckIcon}
    onClick={() => handleDeliverOrder(order.order_id)}
    disabled={
      order.status === 'delivered' || 
      order.status === 'cancelled' || 
      order.tracking_number === null ||
      deliveringOrderId === order.order_id // Disable while delivering
    }
    tooltip={
      order.tracking_number === null 
        ? "No tracking number available" 
        : deliveringOrderId === order.order_id 
          ? "Delivering..." 
          : "Deliver Order"
    }
    loading={deliveringOrderId === order.order_id} // Show loading spinner
  >
    {deliveringOrderId === order.order_id ? 'Delivering...' : 'Deliver Order'}
  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} className="text-center">
                No orders found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      
      <div className="mt-4 flex justify-between items-center">
        <Text>
          Showing {orders.length} of {pagination.total} orders
        </Text>
      </div>
    </Card>
  );
}

export default AllOrdersTable;