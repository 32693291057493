import React from "react";
import LeftColumn from "./admincomps/LeftColumn";
import Navbar from "../../components/navbar/Navbar";
import RightColumn from "./admincomps/RightColumn";
import AdminSidebar from "../../components/sidebar/AdminSidebar";

const AdminPanel = () => {
    return (
        <main className="flex">
          <AdminSidebar />
          <div className="flex flex-col flex-1 relative">
            <Navbar />
            <div className="grid md:grid-cols-3 grid-cols-1 w-full">
              <div className="col-span-2">
                <LeftColumn />
              </div>
              <div className="w-full">
                <RightColumn />
              </div>
            </div>
          </div>
        </main>
      );
    }

export default AdminPanel;