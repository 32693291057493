import React, { useState } from "react";
import {
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Stack,
  TextField,
  Button,
  Box,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import api from "../../../services/api";
import { parse } from "date-fns";
import { toast } from "react-toastify";
import { redirect } from "react-router-dom";
import { useAuth } from "../../AuthProvider";

const UploadBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  border: "2px dashed #ccc",
  cursor: "pointer",
  transition: "border .3s ease-in-out",
  "&:hover": {
    border: "2px dashed #08C989",
  },
}));

// Helper function to find field by label
const findByLabel = (predictions, label) => {
  const field = predictions.find((p) => p.label === label);
  return field ? field.ocr_text : "";
};

const LicenseVerification = ({ user, setActiveStep }) => {
  const { setUser } = useAuth();
  const [licenseFile, setLicenseFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [licenseData, setLicenseData] = useState({
    licenseNumber: "",
    tradeName: "",
    authorisedSignatory: "",
    legalStatus: "",
    issueDate: "",
    expiryDate: "",
    address: "",
    activities: "",
    licenseUrl: "",
  });

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError("No file selected");
      return;
    }

    const validTypes = ["application/pdf", "image/jpeg", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      setError("Please upload a valid PDF or JPEG file");
      return;
    }

    try {
      setLicenseFile(file);
      setLoading(true);
      setError("");

      const formData = new FormData();
      formData.append("license", file);

      const response = await api.post("/ocr/license", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const predictions = response.data?.raw?.result?.[0]?.prediction || [];

      const buildingName = findByLabel(predictions, "Building_Name");
      const areaName = findByLabel(predictions, "Area_Name");
      const address = [buildingName, areaName].filter(Boolean).join(", ");
      const authorisedSignatory = findByLabel(
        predictions,
        "Authorised_Signatory"
      );
      const softwareHouse = findByLabel(predictions, "Software_House");
      const techResearch = findByLabel(
        predictions,
        "Technology_Research_&_Development"
      );
      const activities = [softwareHouse, techResearch]
        .filter(Boolean)
        .join(", ");

      const extractedData = {
        licenseNumber: findByLabel(predictions, "License_Number"),
        tradeName:
          findByLabel(predictions, "Trading_Name") ||
          findByLabel(predictions, "Licensee"),
        authorisedSignatory: findByLabel(predictions, "Authorised_Signatory"),
        legalStatus: findByLabel(predictions, "Legal_Status"),
        issueDate: findByLabel(predictions, "Issue_Date"),
        expiryDate: findByLabel(predictions, "Expiry_Date"),
        address,
        activities,
        licenseUrl: response.data?.raw?.result?.[0]?.file_url,
      };

      setLicenseData(extractedData);
    } catch (err) {
      console.error("Error processing license:", err);
      setError("Error processing license. Please try again.");
      setLicenseFile(null);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setLicenseData((prev) => ({
      ...prev,
      [field]: value,
    }));

    const fieldMapping = {
      tradeName: "businessName",
      authorisedSignatory: "authorisedSignatory",
      address: "businessAddress",
      licenseNumber: "licenseNumber",
      issueDate: "licenseIssueDate",
      expiryDate: "licenseExpiryDate",
    };
  };

  const handleSubmit = async () => {
    if (!licenseFile) {
      setError("Please upload a license file");
      return;
    }

    if (!licenseData.licenseNumber || !licenseData.tradeName) {
      setError("Please ensure license number and trade name are filled");
      return;
    }

    const issueDate = parse(licenseData.issueDate, "dd/MM/yyyy", new Date());
    const expiryDate = parse(licenseData.expiryDate, "dd/MM/yyyy", new Date());

    setSubmitLoading(true);
    try {
      console.log("LICENSE DATA", { ...licenseData, issueDate, expiryDate });

      const response = await api.post("/ocr/create-merchant-organization", {
        email: user.email,
        businessName: licenseData.tradeName,
        authorisedSignatory: licenseData.authorisedSignatory,
        licenseNumber: licenseData.licenseNumber,
        licenseIssueDate: issueDate,
        licenseExpiryDate: expiryDate,
        licenseFileUrl: licenseData.licenseUrl,
      });

      console.log("Merchant organization created:", response.data);

      if (response.data.success === true) {
        toast.success("License verification successful");
        setActiveStep(2);
        // setUser((prev) => ({
        //   ...prev,
        //   merchant_verification_status: "LICENSE_VERIFIED",
        // }));
        // redirect("/panel");
      } else {
        toast.error(response.data.message || "License verification failed");
      }
    } catch (error) {
      setError("Error proceeding to verification");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <div className="upload-section">
        <Typography variant="h6" gutterBottom>
          Upload Trade License
        </Typography>
        <input
          type="file"
          accept="application/pdf,image/jpeg,image/jpg"
          style={{ display: "none" }}
          id="license-upload"
          onChange={handleFileUpload}
          disabled={loading}
        />
        <label htmlFor="license-upload">
          <UploadBox>
            <CloudUploadIcon sx={{ fontSize: 48, color: "#08C989", mb: 2 }} />
            <Typography>
              {licenseFile
                ? `${licenseFile.name} - Click to change`
                : "Click or drag to upload your trade license (PDF or JPEG)"}
            </Typography>
            {loading && <CircularProgress size={24} sx={{ mt: 1 }} />}
          </UploadBox>
        </label>
      </div>

      {licenseFile && !loading && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            License Details
          </Typography>
          <Stack spacing={2}>
            <TextField
              label="License Number"
              value={licenseData.licenseNumber}
              onChange={(e) =>
                handleInputChange("licenseNumber", e.target.value)
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Trade Name"
              value={licenseData.tradeName}
              onChange={(e) => handleInputChange("tradeName", e.target.value)}
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Authorised Signatory"
              value={licenseData.authorisedSignatory}
              onChange={(e) =>
                handleInputChange("authorisedSignatory", e.target.value)
              }
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Legal Status"
              value={licenseData.legalStatus}
              onChange={(e) => handleInputChange("legalStatus", e.target.value)}
              fullWidth
              size="small"
            />
            <TextField
              label="Issue Date"
              value={licenseData.issueDate}
              onChange={(e) => handleInputChange("issueDate", e.target.value)}
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Expiry Date"
              value={licenseData.expiryDate}
              onChange={(e) => handleInputChange("expiryDate", e.target.value)}
              fullWidth
              size="small"
              required
            />
            <TextField
              label="Address"
              value={licenseData.address}
              onChange={(e) => handleInputChange("address", e.target.value)}
              fullWidth
              size="small"
              multiline
              rows={2}
              required
            />
            <TextField
              label="Activities"
              value={licenseData.activities}
              onChange={(e) => handleInputChange("activities", e.target.value)}
              fullWidth
              size="small"
              multiline
              rows={2}
            />
          </Stack>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={submitLoading}
              sx={{
                bgcolor: "#08C989",
                "&:hover": { bgcolor: "#07b37b" },
                minWidth: 180,
                height: 36,
                position: "relative",
              }}
            >
              {submitLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "white",
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                "Proceed to Verification"
              )}
            </Button>
          </Box>
        </Paper>
      )}
    </Stack>
  );
};

export default LicenseVerification;
