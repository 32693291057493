// pages/Dashboard.jsx
import React, { useState, useEffect } from 'react';
import Navbar from "../../components/navbar/Navbar";
import UserSidebar from "../../components/sidebar/UserSidebar";
import WelcomeBanner from './usercomps/WelcomeBanner';
import Widget from './usercomps/Widget';
import TrackingTable from '../../components/table/Table';

const UserPanel = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Sidebar */}
      <UserSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* Site header */}
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            <WelcomeBanner />
            
            {/* Widgets */}
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="pending" />
              </div>
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="order" />
              </div>
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="earning" />
              </div>
              <div className="col-span-full sm:col-span-6 xl:col-span-3">
                <Widget type="past" />
              </div>
            </div>

            {/* Active Orders Section */}
            <div className="col-span-full mt-8">
              <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
                <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
                  <h2 className="font-semibold text-slate-800 dark:text-slate-100">Active Orders</h2>
                </header>
                <div className="p-3">
                  <TrackingTable />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserPanel;