import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID,
    authority: 'https://3shep.b2clogin.com/3shep.onmicrosoft.com/B2C_1_signin',
    knownAuthorities: ['3shep.b2clogin.com'],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    validateAuthority: false
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

// Add password reset authority
const b2cPolicies = {
  passwordReset: {
    authority: 'https://3shep.b2clogin.com/3shep.onmicrosoft.com/B2C_1_pwd_reset'
  }
};

const loginRequest = {
  scopes: ['openid', 'profile', 'email'],
  prompt: 'select_account',
  account: null
};

// Create MSAL instance but don't initialize yet
const msalInstance = new PublicClientApplication(msalConfig);
let isMsalInitialized = false;

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);


    console.log("USER==>", user);
    

    const getUserFromToken = useCallback(() => {
      const token = sessionStorage.getItem('token');
      if (!token) return null;
      try {
        const decoded = jwtDecode(token);
        console.log('Decoded token:', decoded);
        // If this is a team member, decoded.id will already be the owner's ID
        // and decoded.original_id will be the team member's ID
        return {
          ...decoded,
          // Ensure we're using the correct ID (owner's ID for team members)
          id: decoded.id,
          originalId: decoded.original_id,
          isTeamMember: decoded.isTeamMember,
          organizationId: decoded.organizationId || decoded.organization_id, 
          role: decoded.role
        };
        
      } catch {
        return null;
      }
    }, []);
  
    const setAuthToken = useCallback((token) => {
      if (token) {
        sessionStorage.setItem('token', token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        sessionStorage.removeItem('token');
        delete api.defaults.headers.common['Authorization'];
      }
    }, []);
  
    const initializeMsal = useCallback(async () => {
      if (!isMsalInitialized) {
        try {
          console.log('Initializing MSAL...');
          await msalInstance.initialize();
          isMsalInitialized = true;
          console.log('MSAL initialized successfully');
        } catch (error) {
          console.error('MSAL initialization error:', error);
          throw error;
        }
      }
    }, []);

    // Handle the silent token acquisition and user restoration
    const restoreUserSession = useCallback(async () => {
      try {
        // First check for existing token
        const token = sessionStorage.getItem('token');
        console.log('Restoring session - Token exists:', !!token);
    
        if (token) {
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          try {
            const verifyResponse = await api.get('/auth/verify');
            // Set user state from verification response
            if (verifyResponse.data?.user) {
              setUser(verifyResponse.data.user);  // Add this line
              console.log('User restored from token:', verifyResponse.data.user);
            }
            setIsInitialized(true);
            setIsLoading(false);
            return;
          } catch (verifyError) {
            console.error('Token verification failed:', verifyError.response?.data || verifyError.message);
            setAuthToken(null);
            setUser(null);  // Add this line
          }
        }
    
        // Try MSAL session
        const accounts = msalInstance.getAllAccounts();
        console.log('MSAL accounts found:', accounts.length);
    
        if (accounts.length > 0) {
          const account = accounts[0];
          msalInstance.setActiveAccount(account);
    
          try {
            const silentRequest = {
              scopes: loginRequest.scopes,
              account: account,
              forceRefresh: false
            };
    
            console.log('Attempting silent token acquisition');
            const response = await msalInstance.acquireTokenSilent(silentRequest);
    
            console.log('Sending login request to backend');
            const apiResponse = await api.post('/auth/login', {
              email: account.username,
              idToken: response.idToken,
              accountId: account.localAccountId
            });
    
            if (apiResponse.data?.token) {
              console.log('Login successful, setting new token');
              setAuthToken(apiResponse.data.token);
            } else {
              console.warn('Login response missing token');
            }
          } catch (silentError) {
            console.error('Silent token acquisition failed:', {
              error: silentError.message,
              errorCode: silentError.errorCode,
              subError: silentError.subError
            });
            setAuthToken(null);
          }
        } else {
          console.log('No MSAL accounts found');
        }
      } catch (error) {
        console.error('Session restoration error:', {
          message: error.message,
          stack: error.stack
        });
        setAuthToken(null);
        setUser(null);  // Add this line
      } finally {
        setIsInitialized(true);
        setIsLoading(false);
      }
    }, [setAuthToken]);
    
    useEffect(() => {
      const initialize = async () => {
        try {
          console.log('Starting initialization');
          await initializeMsal();
          console.log('MSAL initialized successfully');
          await restoreUserSession();
        } catch (error) {
          console.error('Initialization error:', {
            message: error.message,
            stack: error.stack
          });
          setIsInitialized(true);
          setIsLoading(false);
        }
      };
    
      initialize();
    }, [initializeMsal, restoreUserSession]);
    
    const handleAuthentication = useCallback(async (authResult) => {
      try {
        if (!authResult?.account || !authResult.idToken) {
          throw new Error('Invalid authentication result');
        }
    
        console.log('MSAL Auth Result:', {
          account: authResult.account,
          hasIdToken: !!authResult.idToken,
          claims: authResult.account.idTokenClaims
        });
    
        // This endpoint is specifically for MSAL authentication
        const response = await api.post('/auth/login', {
          email: authResult.account.username,
          idToken: authResult.idToken,
          accountId: authResult.account.localAccountId,
          claims: {
            tid: authResult.account.idTokenClaims?.tid,
            oid: authResult.account.idTokenClaims?.oid,
            preferred_username: authResult.account.idTokenClaims?.preferred_username,
            name: authResult.account.idTokenClaims?.name
          }
        });
    
        if (!response.data?.token) {
          throw new Error('No token received from server');
        }
    
        setAuthToken(response.data.token);
        const userData = jwtDecode(response.data.token);

        console.log("USERDATA==>", userData); 

        setUser({
          ...userData,
          id: userData.id,
          originalId: userData.original_id,
          isTeamMember: userData.isTeamMember,
          organizationId: userData.organizationId || userData.organization_id,
          merchant_verification_status : userData.merchant_verification_status,
        });

        switch(userData.role.toLowerCase()) {
          case 'owner':
          case 'merchant':
          case 'shipping':
          case 'finance': 
            if (userData.merchant_verification_status !== "VERIFIED") 
              toast.warning("Your account is not verified, please complete the verification process");
            navigate('/panel', { replace: true });
            break;
          case 'user':
            navigate('/userpanel', { replace: true });
            break;
          default:
            throw new Error(`Invalid user role: ${userData.role}`);
        }
        toast.success('Welcome back!');
        return response.data;
    
      } catch (error) {
        console.error('MSAL Authentication error:', {
          error,
          response: error.response?.data,
          status: error.response?.status
        });
        toast.error(error.message || 'Authentication failed');
        throw error;
      }
    }, [navigate, setAuthToken]);
  
    const googleLogin = useCallback(async (credential) => {
      setIsLoading(true);
      setError(null);
    
      try {
        console.log('Starting Google login process');
        const response = await api.post('/auth/google', { credential });
        console.log('Google login response:', response.data);
    
        if (!response.data?.token) {
          throw new Error('No token received from server');
        }
    
        const token = response.data.token;
        
        // Store token
        sessionStorage.setItem('token', token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
        // Decode and set user data
        const userData = jwtDecode(token);
        console.log('Decoded token:', userData);
    
        // Set user data explicitly
        setUser({
          ...response.data.user,
          id: userData.id,
          email: userData.email,
          name: userData.name,
          role: userData.role,
          organizationId: userData.organizationId || response.data.user.organizationId
        });
    
        // Navigate based on role
        if (['owner', 'merchant', 'shipping', 'finance'].includes(userData.role.toLowerCase())) {
          navigate('/panel', { replace: true });
        } else if (userData.role.toLowerCase() === 'user') {
          navigate('/userpanel', { replace: true });
        }
    
        return response.data;
      } catch (error) {
        console.error('Google authentication error:', error);
        sessionStorage.removeItem('token');
        delete api.defaults.headers.common['Authorization'];
        throw error;
      } finally {
        setIsLoading(false);
      }
    }, [navigate, setUser]);
  

      const login = useCallback(async (email) => {
        setIsLoading(true);
        setError(null);
      
        try {
          await initializeMsal();
          console.log('Starting login process for:', email);
      
          const accounts = msalInstance.getAllAccounts();
          const existingAccount = accounts.find(acc => 
            acc.username.toLowerCase() === email.toLowerCase()
          );
      
          let authResult;
          try {
            if (existingAccount) {
              authResult = await msalInstance.ssoSilent({
                scopes: loginRequest.scopes,
                account: existingAccount,
                forceRefresh: true
              });
            } else {
              authResult = await msalInstance.loginPopup({
                ...loginRequest,
                prompt: 'select_account', // Forces account selection
                loginHint: email
              });
            }
            
            const userData = await handleAuthentication(authResult);
            return userData;
          } catch (error) {
            if (error.errorMessage?.includes('AADB2C90118')) {
              // Password reset flow
              try {
                await msalInstance.loginPopup({
                  authority: b2cPolicies.passwordReset.authority,
                  scopes: ['openid'],
                  prompt: 'login',
                  loginHint: email
                });
                
                // After password reset, retry login
                authResult = await msalInstance.loginPopup({
                  ...loginRequest,
                  loginHint: email
                });
                
                const userData = await handleAuthentication(authResult);
                return userData;
              } catch (resetError) {
                if (resetError.errorCode === 'user_cancelled') {
                  toast.info('Password reset was cancelled');
                } else {
                  console.error('Password reset error:', resetError);
                  toast.error('Password reset failed. Please try again.');
                }
                throw resetError;
              }
            }
            throw error;
          }
        } catch (error) {
          console.error('Login error:', error);
          setError(error.message);
          toast.error(error.message || 'Login failed. Please try again.');
          throw error;
        } finally {
          setIsLoading(false);
        }
      }, [handleAuthentication, initializeMsal]);
      
      // Add resetPassword function
      const resetPassword = useCallback(async (email) => {
        setIsLoading(true);
        try {
          await initializeMsal();
          await msalInstance.loginPopup({
            authority: b2cPolicies.passwordReset.authority,
            scopes: ['openid'],
            prompt: 'login',
            loginHint: email
          });
          toast.success('Password reset successful');
        } catch (error) {
          if (error.errorCode === 'user_cancelled') {
            toast.info('Password reset was cancelled');
          } else {
            console.error('Password reset error:', error);
            toast.error('Password reset failed');
          }
          throw error;
        } finally {
          setIsLoading(false);
        }
      }, [initializeMsal]);

  const updateUserData = useCallback(async (newUserData) => {
        try {
          // Update the context user data
          const token = sessionStorage.getItem('token');
          if (token) {
            // Just update the session without making an API call
            // since we already have the new avatar URL
            setUser(newUserData);
            return true;
          }
          return false;
        } catch (error) {
          console.error('Error updating user data:', error);
          return false;
        }
      }, []);

  const logout = useCallback(async () => {
    setIsLoading(true);
    try {
      await initializeMsal(); // Ensure MSAL is initialized before logout
      
      await api.post('/auth/logout', {}, { withCredentials: true });
      
      const account = msalInstance.getActiveAccount();
      if (account) {
        await msalInstance.logoutPopup();
      }

      sessionStorage.clear();
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
      setUser(null);
      setError(null);
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      setError('Logout failed');
    } finally {
      setIsLoading(false);
    }
  }, [navigate, initializeMsal]);

  const handleUaePassLogin = useCallback(() => {
    window.location.href = 'https://staging-backend.mangodesert-784b53bb.uaenorth.azurecontainerapps.io/auth/uaepass';
  }, []);

  const handleUaePassCallback = useCallback(async (token, email, name, role) => {
    try {
      if (!token || !email) {
        throw new Error('Missing required authentication parameters');
      }
  
      console.log('Processing UAE Pass callback:', { email, name, role });
  
      // Verify and decode the token
      const decodedToken = jwtDecode(token);
      console.log('Decoded UAE Pass token:', decodedToken);
  
      // Set the token
      setAuthToken(token);
  
      // Create user object with decoded token data
      const userData = {
        id: decodedToken.id,
        email: decodedToken.email,
        name: decodedToken.name,
        role: decodedToken.role,
        isAuthenticated: true
      };
  
      // Update user in context
      setUser(userData);
      setError(null);
  
      // Navigate based on role
      const normalizedRole = role.toLowerCase();
      if (normalizedRole === 'user') {
        navigate('/userpanel', { replace: true });
      } else if (['owner', 'merchant', 'shipping', 'finance'].includes(normalizedRole)) {
        navigate('/panel', { replace: true });
      } else {
        throw new Error(`Invalid user role: ${role}`);
      }
  
      toast.success('Welcome back!');
      return true;
    } catch (error) {
      console.error('UAE Pass callback processing error:', error);
      setAuthToken(null);
      setUser(null);
      setError('Authentication failed');
      toast.error('Failed to complete authentication. Please try again.');
      navigate('/login', { replace: true });
      return false;
    }
  }, [navigate, setAuthToken, setUser, setError]);
  
    
  

  const register = useCallback(async (userData) => {
    setIsLoading(true);
    try {
      const route = userData.userType === 'user' 
        ? '/auth/register' 
        : '/auth/merchant-register';

      console.log("ROUTE==>", route);
      console.log("USERDATA==>", userData);
      
      const response = await api.post(route, userData);

      console.log("REGISTER_RES==> ",response.data);
      
      if (response.data.success) {
        return await login(userData.email);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Registration failed');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [login]);

  

  const updateProfile = async (userData) => {
    try {
      const response = await api.put('/auth/profile', userData, {
        withCredentials: true
      });
      if (response.data.user) {
        setUser(response.data.user);
      }
      return response.data;
    } catch (error) {
      setError(error.response?.data?.message || 'Profile update failed');
      throw error;
    }
  };

  const value = {
    user,
    login,
    logout,
    updateUserData,
    register,
    googleLogin,
    resetPassword,
    updateProfile,
    handleUaePassLogin, // Add this line
    handleUaePassCallback,
    //checkSession,
    isLoading,
    error,
    isAuthenticated: !!sessionStorage.getItem('token'),
    getUser: getUserFromToken,
    isInitialized, // Add this line
    setError, // Expose error setter for custom error handling
    setUser
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;