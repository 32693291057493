import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';

export default function AddressForm({ formData, setFormData, onValidationChange }) {
  const [errors, setErrors] = useState({});

  

  // Required fields list
  const requiredFields = ['firstName', 'lastName', 'email', 'phone', 'address1', 'city', 'zip', 'country'];

  // Check if all required fields are filled and valid
  useEffect(() => {
    if (typeof onValidationChange === 'function') {
      const isValid = requiredFields.every(field => 
        formData[field] && 
        formData[field].trim() !== '' && 
        !errors[field]
      );
      onValidationChange(isValid);
    }
  }, [formData, errors, onValidationChange, requiredFields]);
  
  const validateField = (name, value) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        return value.length >= 2 ? '' : 'Must be at least 2 characters';
      
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value) ? '' : 'Invalid email format';
      
      case 'phone':
        // UAE phone number format: +971 followed by 9 digits
        // Accepts formats like: +971501234567, +971-50-1234567
        const phoneRegex = /^\+971[0-9]{9}$/;
        const cleanPhone = value.replace(/[-\s]/g, '');
        return phoneRegex.test(cleanPhone) ? '' : 'Invalid UAE phone number format';
      
      case 'address1':
        return value.length >= 5 ? '' : 'Address is too short';
      
      case 'city':
        return value.length >= 2 ? '' : 'City name is too short';
      
      case 'zip':
        const zipRegex = /^\d{5}(-\d{4})?$/;
        return zipRegex.test(value) ? '' : 'Invalid ZIP code format (12345 or 12345-6789)';
      
      case 'country':
        return value.length >= 2 ? '' : 'Please enter a valid country';
      
      default:
        return '';
    }
  };

  
  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'phone') {
      let phoneValue = value;
      if (!phoneValue.startsWith('+971')) {
        phoneValue = '+971' + phoneValue.replace(/^\+971/, '');
      }
      phoneValue = phoneValue.replace(/[^\d+]/g, '');
      setFormData({ ...formData, [name]: phoneValue });
      const error = validateField(name, phoneValue);
      setErrors(prev => ({
        ...prev,
        [name]: error
      }));
      return;
    }
    
    setFormData({ ...formData, [name]: value });
    
    if (event.target.required) {
      const error = validateField(name, value);
      setErrors(prev => ({
        ...prev,
        [name]: error
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (event.target.required) {
      const error = value.trim() === '' ? 'This field is required' : validateField(name, value);
      setErrors(prev => ({
        ...prev,
        [name]: error
      }));
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            value={formData.firstName || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            value={formData.lastName || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Primary Email"
            fullWidth
            autoComplete="email"
            value={formData.email || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Phone Number"
            fullWidth
            autoComplete="tel"
            value={formData.phone || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.phone}
            helperText={errors.phone || 'Format: +971501234567'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {!formData.phone && '+971'}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            value={formData.address1 || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.address1}
            helperText={errors.address1}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            value={formData.address2 || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            value={formData.city || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.city}
            helperText={errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            value={formData.state || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            value={formData.zip || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.zip}
            helperText={errors.zip}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            value={formData.country || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.country}
            helperText={errors.country}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}