import React from "react";
import { BadgeDelta, Card, Flex, Metric, Text } from "@tremor/react";

const CardItem = ({ title, deltaType, deltaValue, metricValue }) => {
  return (
    <Card className="w-xs" decoration="top" decorationColor="emerald">
      <Flex justifyContent="between" alignItems="center">
        <Text>{title}</Text>
        <BadgeDelta deltaType={deltaType}>{deltaValue}</BadgeDelta>
      </Flex>
      <Metric>{metricValue}</Metric>
    </Card>
  );
};

export default CardItem;