import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
//import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51Ox1l201Rlm3UcOUmKLl5XeyYetagFyrEbBwvUBxXMS8v4nNw9bCXsRwHU7ZRIZqsyy6ChT2SRasBj00I9Jh39eJ00IkBgUeHl");

export default function App({formData, shippingRate, selectedService}) {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const createPaymentIntent = async () => {
      const endpoint = process.env.NODE_ENV === 'production' 
        ? 'https://staging-backend.mangodesert-784b53bb.uaenorth.azurecontainerapps.io/create-payment-intent'
        : 'http://localhost:5001/create-payment-intent';
      console.log('Selected Service being sent to backend:', selectedService);

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            // items: [
            //   { name: 'Product 1', price: 9.99, quantity: 1 },
            //   { name: 'Product 2', price: 3.45, quantity: 1 },
            //   { name: 'Product 3', price: 6.51, quantity: 1 },
            //   { name: 'Product 4', price: 14.11, quantity: 1 },
            // ],
            shippingRate: shippingRate,
            total_amount: formData.total_amount,
            user_id: formData.user_id,
            merchant_id: formData.merchant_id,
            selectedService: selectedService, // Include selectedService here
            billing_details: {
              name: `${formData.firstName} ${formData.lastName}`,
              email: formData.email,
              phone: formData.phone,
              address: {
                line1: formData.address1,
                line2: formData.address2,
                city: formData.city,
                state: formData.state,
                postal_code: formData.zip,
                country: formData.country
              }
            }
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    createPaymentIntent();
  }, [shippingRate, selectedService, formData]);

  const appearance = {
    theme: 'flat',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm formData={formData} selectedService={selectedService}/>
        </Elements>
      )}
    </div>
  );
}