import Sidebar from '../sidebar/Sidebar'; 
import Navbar from '../navbar/Navbar'; 
import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Paper, Typography, Box, MenuItem, CircularProgress, Link } from '@mui/material';

const CreateShipmentForm = () => {
  const [formData, setFormData] = useState({
    accountNumber: '740561073',
    pickupType: 'USE_SCHEDULED_PICKUP',
    serviceType: 'STANDARD_OVERNIGHT',
    packagingType: 'YOUR_PACKAGING',
    shipDatestamp: '2020-07-03',
    shipperName: 'SHIPPER NAME',
    shipperPhone: '1234567890',
    shipperCompany: 'Shipper Company Name',
    shipperAddress: 'SHIPPER STREET LINE 1',
    shipperCity: 'DUBAI CITY',
    shipperState: 'DU',
    shipperZip: '20001',
    shipperCountry: 'AE',
    recipientName: 'RECIPIENT NAME',
    recipientPhone: '1234567890',
    recipientCompany: 'Recipient Company Name',
    recipientAddress1: 'RECIPIENT STREET LINE 1',
    recipientAddress2: 'RECIPIENT STREET LINE 2',
    recipientCity: 'SHARJAH CITY',
    recipientState: 'SH',
    recipientZip: '30001',
    recipientCountry: 'AE',
    declaredValue: 100,
    currency: 'AED',
    weightValue: 10,
    weightUnits: 'KG',
    itemDescription: 'Item description for clearance',
    labelImageType: 'PDF',
    labelStockType: 'PAPER_85X11_TOP_HALF_LABEL',
  });

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const payload = {
        labelResponseOptions: 'URL_ONLY',
        requestedShipment: {
          shipper: {
            contact: {
              personName: formData.shipperName,
              phoneNumber: formData.shipperPhone,
              companyName: formData.shipperCompany,
            },
            address: {
              streetLines: [formData.shipperAddress],
              city: formData.shipperCity,
              stateOrProvinceCode: formData.shipperState,
              postalCode: formData.shipperZip,
              countryCode: formData.shipperCountry,
            },
          },
          recipients: [{
            contact: {
              personName: formData.recipientName,
              phoneNumber: formData.recipientPhone,
              companyName: formData.recipientCompany,
            },
            address: {
              streetLines: [formData.recipientAddress1, formData.recipientAddress2],
              city: formData.recipientCity,
              stateOrProvinceCode: formData.recipientState,
              postalCode: formData.recipientZip,
              countryCode: formData.recipientCountry,
            },
          }],
          shipDatestamp: formData.shipDatestamp,
          serviceType: formData.serviceType,
          packagingType: formData.packagingType,
          pickupType: formData.pickupType,
          blockInsightVisibility: false,
          shippingChargesPayment: {
            paymentType: 'SENDER',
          },
          labelSpecification: {
            imageType: formData.labelImageType,
            labelStockType: formData.labelStockType,
          },
          requestedPackageLineItems: [{
            declaredValue: {
              amount: formData.declaredValue,
              currency: formData.currency,
            },
            weight: {
              value: formData.weightValue,
              units: formData.weightUnits,
            },
            itemDescriptionForClearance: formData.itemDescription,
          }],
        },
        accountNumber: {
          value: formData.accountNumber,
        },
      };

      const createResponse = await axios.post('https://staging-backend.mangodesert-784b53bb.uaenorth.azurecontainerapps.io/fedex/create', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setResponse(createResponse.data);
      setLoading(false);
    } catch (error) {
      console.error('Error creating shipment:', error);
      setError('Failed to create shipment');
      setLoading(false);
    }
  };

  return (
    <Box display="flex">
      <Sidebar sx={{ width: '240px' }} /> {/* Adjust sidebar width here */}
      <Box flexGrow={3.5}>
        <Navbar />
        <Container maxWidth="md">
          <Paper elevation={3} sx={{ padding: 4, mt: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Create Shipment
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                name="accountNumber"
                label="Account Number"
                value={formData.accountNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                select
                name="pickupType"
                label="Pickup Type"
                value={formData.pickupType}
                onChange={handleChange}
                fullWidth
                margin="normal"
              >
                <MenuItem value="USE_SCHEDULED_PICKUP">Use Scheduled Pickup</MenuItem>
                <MenuItem value="REQUEST_COURIER">Request Courier</MenuItem>
              </TextField>
              <TextField
                select
                name="serviceType"
                label="Service Type"
                value={formData.serviceType}
                onChange={handleChange}
                fullWidth
                margin="normal"
              >
                <MenuItem value="STANDARD_OVERNIGHT">Standard Overnight</MenuItem>
                <MenuItem value="PRIORITY_OVERNIGHT">Priority Overnight</MenuItem>
                <MenuItem value="FEDEX_GROUND">FedEx Ground</MenuItem>
              </TextField>
              <TextField
                name="packagingType"
                label="Packaging Type"
                value={formData.packagingType}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="shipDatestamp"
                label="Ship Datestamp"
                type="date"
                value={formData.shipDatestamp}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                required
              />
              <Typography variant="h6" component="h2" gutterBottom>
                Shipper Information
              </Typography>
              <TextField
                name="shipperName"
                label="Shipper Name"
                value={formData.shipperName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="shipperPhone"
                label="Shipper Phone"
                value={formData.shipperPhone}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="shipperCompany"
                label="Shipper Company"
                value={formData.shipperCompany}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="shipperAddress"
                label="Shipper Address"
                value={formData.shipperAddress}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="shipperCity"
                label="Shipper City"
                value={formData.shipperCity}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="shipperState"
                label="Shipper State"
                value={formData.shipperState}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="shipperZip"
                label="Shipper ZIP"
                value={formData.shipperZip}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="shipperCountry"
                label="Shipper Country"
                value={formData.shipperCountry}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <Typography variant="h6" component="h2" gutterBottom>
                Recipient Information
              </Typography>
              <TextField
                name="recipientName"
                label="Recipient Name"
                value={formData.recipientName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="recipientPhone"
                label="Recipient Phone"
                value={formData.recipientPhone}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="recipientCompany"
                label="Recipient Company"
                value={formData.recipientCompany}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="recipientAddress1"
                label="Recipient Address Line 1"
                value={formData.recipientAddress1}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="recipientAddress2"
                label="Recipient Address Line 2"
                value={formData.recipientAddress2}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="recipientCity"
                label="Recipient City"
                value={formData.recipientCity}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="recipientState"
                label="Recipient State"
                value={formData.recipientState}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="recipientZip"
                label="Recipient ZIP"
                value={formData.recipientZip}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="recipientCountry"
                label="Recipient Country"
                value={formData.recipientCountry}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <Typography variant="h6" component="h2" gutterBottom>
                Package Details
              </Typography>
              <TextField
                name="declaredValue"
                label="Declared Value (USD)"
                type="number"
                value={formData.declaredValue}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="weightValue"
                label="Weight (KG)"
                type="number"
                value={formData.weightValue}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="itemDescription"
                label="Item Description for Clearance"
                value={formData.itemDescription}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <Typography variant="h6" component="h2" gutterBottom>
                Label Specification
              </Typography>
              <TextField
                name="labelImageType"
                label="Label Image Type"
                value={formData.labelImageType}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                name="labelStockType"
                label="Label Stock Type"
                value={formData.labelStockType}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
              <Button variant="contained" type="submit" sx={{ mt: 3 }} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Create Shipment'}
              </Button>
              {response && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" color="success.main">
                    Shipment created successfully! Tracking Number: {response.output.transactionShipments[0].masterTrackingNumber}
                  </Typography>
                  <Typography variant="body2">
                    <Link href={response.output.transactionShipments[0].pieceResponses[0].packageDocuments[0].url} target="_blank" rel="noopener noreferrer">
                      Download Label
                    </Link>
                  </Typography>
                </Box>
              )}
              {error && (
                <Typography variant="body1" color="error.main" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
            </form>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default CreateShipmentForm;
