import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FrameComponent4 from "./home/FrameComponent4";
import { useAuth } from "./AuthProvider"; // Update path as needed
import { CiWarning } from "react-icons/ci";

const Navigation = () => {
  const { user, logout, isLoading } = useAuth();
  const userData = user;
  const navigate = useNavigate();

  // Public routes visible to all users
  const publicLinks = [
    { to: "/", label: "Home" },
    { to: "/about-us", label: "About Us" },
    { to: "/contact-us", label: "Contact" },
  ];

  // Auth routes only visible to logged-out users
  const authLinks = [
    { to: "/login", label: "Login" },
    //{ to: "/register", label: "Register" },
  ];

  // Protected routes based on user roles
  const protectedLinks = {
    admin: [{ to: "/adminpanel", label: "Admin Panel" }],
    owner: [{ to: "/panel", label: "Merchant Panel" }],
    finance: [{ to: "/panel", label: "Merchant Panel" }],
    shipping: [{ to: "/panel", label: "Merchant Panel" }],
    user: [{ to: "/userpanel", label: "User Panel" }],
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const renderNavLinks = () => {
    let links = [...publicLinks];

    if (!isLoading) {
      if (!userData) {
        links = [...links, ...authLinks];
      } else {
        // Add role-specific links based on user's role
        if (userData.role && protectedLinks[userData.role.toLowerCase()]) {
          links = [...links, ...protectedLinks[userData.role.toLowerCase()]];
        }
      }
    }

    return links.map(({ to, label }) => (
      <NavLink key={to} to={to}>
        {label}
      </NavLink>
    ));
  };

  // Optional loading state
  if (isLoading) {
    return (
      <FrameComponent4>
        <div className="flex flex-row items-center justify-end gap-[15px] max-w-full">
          {publicLinks.map(({ to, label }) => (
            <NavLink key={to} to={to}>
              {label}
            </NavLink>
          ))}
        </div>
      </FrameComponent4>
    );
  }

  return (
    <>
      <FrameComponent4>
        <div className="flex flex-row items-center justify-end gap-[15px] max-w-full">
          {renderNavLinks()}
          {userData &&
            userData.role !== "user" &&
            userData.merchant_verification_status !== "VERIFIED" && (
              <NavLink to="/merchant-verification">Verification</NavLink>
            )}
          {userData && (
            <div className="flex items-center gap-4">
              <span className="text-sm font-medium">
                Welcome, {userData.name || userData.email}
              </span>
              <button
                onClick={handleLogout}
                className="rounded-6xs flex flex-row items-center justify-center p-2.5"
              >
                <span className="[text-decoration:none] relative tracking-[-0.02em] font-semibold text-[inherit] inline-block min-w-[45px] whitespace-nowrap">
                  Logout
                </span>
              </button>
            </div>
          )}
        </div>
      </FrameComponent4>

      {/* {userData &&
        userData.merchant_verification_status !== "VERIFIED" &&
        !isRoot && (
          <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between">
            <div className="flex items-center space-x-3 mb-3 sm:mb-0">
              <CiWarning className="h-6 w-6 text-yellow-600 flex-shrink-0" />
              <div>
                <h2 className="font-semibold text-yellow-800">
                  Verification Required
                </h2>
                <p className="text-yellow-700 text-sm">
                  Please complete the verification process to access all
                  features.
                </p>
              </div>
            </div>
            <button
              onClick={() => navigate("/merchant-verification")}
              className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
            >
              Verify Now
            </button>
          </div>
        )} */}
    </>
  );
};

const NavLink = ({ to, children }) => (
  <div className="rounded-6xs flex flex-row items-center justify-center p-2.5">
    <Link
      to={to}
      className="[text-decoration:none] relative tracking-[-0.02em] font-semibold text-[inherit] inline-block min-w-[45px] whitespace-nowrap"
    >
      {children}
    </Link>
  </div>
);

export default Navigation;
