import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthProvider';
import defaultAvatar from '../../pages/panel/images/user-avatar-80.png';
import { Button } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import LockReset from '@mui/icons-material/LockReset';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import api from '../../services/api';

function CompleteProfileForm() {
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [businessData, setBusinessData] = useState(null);
    const { getUser } = useAuth();
    const authUser = getUser();
    const fileInputRef = React.useRef();

    useEffect(() => {
        fetchBusinessData();
    }, [authUser?.id]); // Add dependency on authUser.id

    
    const handleAvatarChange = async (event) => {
      const file = event.target.files[0];
      if (!file) return;
  
      // Check file size (e.g., 5MB limit)
      if (file.size > 5 * 1024 * 1024) {
          setFeedback('File size should be less than 5MB');
          return;
      }
  
      const reader = new FileReader();
      reader.onload = async (e) => {
          try {
              setFeedback('');
              setIsLoading(true);
              
              const base64Image = e.target.result;
              
              // Update UI immediately
              setBusinessData(prev => ({
                  ...prev,
                  avatar: base64Image
              }));
  
              // Send to backend
              const response = await api.post('/business/avatar', {
                  avatar: base64Image,
                  userId: authUser.id
              });
  
              if (response.data.success) {
                  // Update business data state
                  setBusinessData(prev => ({
                      ...prev,
                      avatar: response.data.avatar  // Make sure this matches backend response
                  }));
                  
                  // Update session storage
                  const storedUserData = JSON.parse(sessionStorage.getItem('userData') || '{}');
                  const updatedUserData = {
                      ...storedUserData,
                      avatar: response.data.avatar
                  };
                  sessionStorage.setItem('userData', JSON.stringify(updatedUserData));
                  
                  setFeedback('Company logo updated successfully');
              } else {
                  throw new Error(response.data.error || 'Failed to update company logo');
              }
          } catch (error) {
              console.error('Error uploading company logo:', error);
              setFeedback(error.message || 'Failed to update company logo');
              // Revert the image on error
              await fetchBusinessData();
          } finally {
              setIsLoading(false);
          }
      };
  
      reader.onerror = () => {
          setFeedback('Error reading file');
          setIsLoading(false);
      };
  
      reader.readAsDataURL(file);
  };
  
  // Update fetchBusinessData to handle auth user data
  const fetchBusinessData = async () => {
    if (!authUser?.id) return;
    
    try {
        setIsLoading(true);
        const { data } = await api.get(`/business/merchant/${authUser.id}`);
        
        if (data.success && data.data) {  // Note the nested data object
            // Set business data with the merchant data
            setBusinessData(data.data);  // Using data.data instead of data.business
            
            // Update session storage if avatar exists
            if (data.data.avatar) {
                const storedUserData = JSON.parse(sessionStorage.getItem('userData') || '{}');
                const updatedUserData = {
                    ...storedUserData,
                    avatar: data.data.avatar
                };
                sessionStorage.setItem('userData', JSON.stringify(updatedUserData));
            }
        }
    } catch (error) {
        console.error('Error fetching business data:', error);
        setFeedback('Failed to load business data');
    } finally {
        setIsLoading(false);
    }
};
  
  // Add dependency to useEffect
  useEffect(() => {
      if (authUser?.id) {
          fetchBusinessData();
      }
  }, [authUser?.id]);
  
  // Update getAvatarUrl to handle all cases
  const getAvatarUrl = (avatarPath) => {
      if (!avatarPath) return defaultAvatar;
      if (avatarPath.startsWith('data:image')) return avatarPath;
      if (avatarPath.startsWith('http')) return avatarPath;
      return `${process.env.REACT_APP_API_URL}${avatarPath.startsWith('/') ? '' : '/'}${avatarPath}`;
  };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handlePasswordReset = async () => {
        const redirectUri = `${window.location.origin}`;
        const tenant = "3shep";
        const policy = "B2C_1_pwd_reset";
        const clientId = process.env.REACT_APP_AZURE_B2C_CLIENT_ID;
        
        const b2cPasswordResetUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policy}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=id_token&redirect_uri=${encodeURIComponent(redirectUri)}&response_mode=fragment&scope=openid&nonce=${Math.random().toString(36)}`;

        window.location.href = b2cPasswordResetUrl;
    };

    return (
        <div className="grow">
            <div className="p-6 space-y-6">
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">My Profile</h2>
                
                {/* Picture */}
                <section>
                    <div className="flex items-center">
                        <div className="mr-4">
                            {!isLoading && (
                                <img 
                                    className="w-20 h-20 rounded-full object-cover" 
                                    src={getAvatarUrl(businessData?.avatar)} 
                                    width="80" 
                                    height="80" 
                                    alt="Company logo" 
                                    onError={(e) => {
                                        if (e.target.src !== defaultAvatar) {
                                            console.log('Avatar load error, falling back to default');
                                            e.target.src = defaultAvatar;
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <div className="flex flex-col space-y-2">
                            <input
                                type="file"
                                ref={fileInputRef}
                                className="hidden"
                                accept="image/jpeg,image/png"
                                onChange={handleAvatarChange}
                            />
                            <Button
                                onClick={triggerFileInput}
                                variant="contained"
                                startIcon={<PhotoCamera />}
                                size="small"
                                disabled={isLoading}
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#3b82f6',
                                    '&:hover': {
                                        backgroundColor: '#2563eb',
                                    }
                                }}
                            >
                                Upload Company Logo
                            </Button>
                            {feedback && (
                                <div className={`text-sm ${feedback.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
                                    {feedback}
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                {/* Profile Details */}
                <section>
                    <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Profile Details</h2>
                    <div className="text-sm">Manage your personal information.</div>
                    <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                        <div className="sm:w-1/3">
                            <label className="block text-sm font-medium mb-1" htmlFor="name">Full Name</label>
                            <input 
                                id="name" 
                                className="form-input w-full bg-slate-50 cursor-not-allowed" 
                                type="text" 
                                value={authUser?.name || ''} 
                                disabled 
                                readOnly
                            />
                        </div>
                        <div className="sm:w-1/3">
                            <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
                            <input 
                                id="email" 
                                className="form-input w-full bg-slate-50 cursor-not-allowed" 
                                type="email" 
                                value={authUser?.email || ''} 
                                disabled 
                                readOnly
                            />
                        </div>
                    </div>
                </section>

                {/* Password */}
                <section>
                    <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Password</h2>
                    <div className="text-sm">You can set a permanent password if you don't want to use temporary login codes.</div>
                    <div className="mt-5">
                        <Button
                            onClick={handlePasswordReset}
                            variant="outlined"
                            startIcon={<LockReset />}
                            sx={{
                                textTransform: 'none',
                                borderColor: '#3b82f6',
                                color: '#3b82f6',
                                '&:hover': {
                                    borderColor: '#2563eb',
                                    backgroundColor: 'rgba(59, 130, 246, 0.04)',
                                }
                            }}
                        >
                            Reset Password
                        </Button>
                    </div>
                </section>
            </div>

            {/* Panel footer */}
            <footer>
                <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                    <div className="flex self-end space-x-3">
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default CompleteProfileForm;