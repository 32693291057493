import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import api from '../../services/api';

// MUI Icons
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

const MerchantWidget = ({ type }) => {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const diff = 20; // Temporary
  
  const { getUser } = useAuth();
  const userData = getUser();
  const merchantId = userData?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        switch (type) {
          case "pending":
            response = await api.get(`/business/${merchantId}/orders/pending/count`);
            setAmount(response.data.count || 0);
            break;
          case "order":
            response = await api.get(`/business/${merchantId}/orders/delivered`);
            setAmount(response.data.count);
            break;
          case "earning":
            response = await api.get(`/business/${merchantId}/orders/delivered/total-amount`);
            setAmount(response.data.totalAmount || 0);
            break;
          case "wallet":
            response = await api.get(`/business/${merchantId}/orders/not-delivered/total-amount`);
            setAmount(response.data.totalAmount || 0);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(`Error fetching data for ${type}:`, error);
        setAmount(0);
      } finally {
        setLoading(false);
      }
    };

    if (merchantId) {
      fetchData();
    }
  }, [type, merchantId]);

  // Configure widget data based on type
  const getWidgetConfig = () => {
    switch (type) {
      case "pending":
        return {
          title: "Open Shipments",
          isMoney: false,
          link: "See all orders pending delivery",
          linkTo: "/merchant-orders?status=processing",
          icon: (
            <span className="flex items-center justify-center w-8 h-8 rounded-full bg-rose-100 dark:bg-rose-500/20">
              <PersonOutlinedIcon className="text-rose-500" />
            </span>
          ),
          trendColor: "emerald"
        };
      case "order":
        return {
          title: "Completed Orders",
          isMoney: false,
          link: "View all delivered orders",
          linkTo: "/merchant-orders?status=delivered",
          icon: (
            <span className="flex items-center justify-center w-8 h-8 rounded-full bg-amber-100 dark:bg-amber-500/20">
              <ShoppingCartOutlinedIcon className="text-amber-500" />
            </span>
          ),
          trendColor: "amber"
        };
      case "earning":
        return {
          title: "Total Earnings",
          isMoney: true,
          link: "View net earnings",
          linkTo: "/merchant-orders?status=delivered",
          icon: (
            <span className="flex items-center justify-center w-8 h-8 rounded-full bg-emerald-100 dark:bg-emerald-500/20">
              <MonetizationOnOutlinedIcon className="text-emerald-500" />
            </span>
          ),
          trendColor: "emerald"
        };
      case "wallet":
        return {
          title: "Funds On Hold",
          isMoney: true,
          link: "View order history",
          linkTo: "/merchant-orders?status=processing",
          icon: (
            <span className="flex items-center justify-center w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-500/20">
              <AccountBalanceWalletOutlinedIcon className="text-purple-500" />
            </span>
          ),
          trendColor: "purple"
        };
      default:
        return null;
    }
  };

  const config = getWidgetConfig();
  if (!config) return null;

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {config.icon}
          <div className={`text-xs font-semibold text-${config.trendColor}-500 dark:text-${config.trendColor}-500 bg-${config.trendColor}-100 dark:bg-${config.trendColor}-500/20 px-2 py-1 rounded-full`}>
            <div className="flex items-center">
              <KeyboardArrowUpIcon className="w-3 h-3 mr-1" />
              <span>{diff}%</span>
            </div>
          </div>
        </header>
        <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-100 mb-2">{config.title}</h2>
        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-1">Current Period</div>
        <div className="flex items-start">
          <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">
            {loading ? (
              <div className="animate-pulse bg-slate-200 dark:bg-slate-700 h-8 w-24 rounded"></div>
            ) : (
              `${config.isMoney ? "AED " : ""}${amount.toLocaleString()}`
            )}
          </div>
        </div>
      </div>
      <div className="px-5 pb-5">
        <Link
          to={config.linkTo}
          className="text-sm font-medium text-blue-500 hover:text-blue-600 dark:hover:text-blue-400"
        >
          {config.link} →
        </Link>
      </div>
    </div>
  );
};

export default MerchantWidget;