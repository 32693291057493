import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../components/AuthProvider';
import defaultAvatar from '../images/user-avatar-80.png';
import { Button } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import LockReset from '@mui/icons-material/LockReset';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import api from '../../../services/api';

function ProfilePanel() {
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const { getUser } = useAuth();
    const authUser = getUser();
    const fileInputRef = React.useRef();

    const fetchUserData = async () => {
        if (!authUser?.id) return;
        
        try {
            setIsLoading(true);
            const { data } = await api.get(`/users/${authUser.id}`);
            if (data.success) {
                setUserData(data.user);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setFeedback('Failed to load user data');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const handleAvatarChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                setFeedback('');
                
                // Update UI immediately for better UX
                setUserData(prev => ({
                    ...prev,
                    avatar: e.target.result
                }));

                // Send to backend using your existing route
                const response = await api.post('/users/avatar', {
                    avatar: e.target.result,
                    userId: authUser.id
                });

                if (response.data.success) {
                    setUserData(prev => ({
                        ...prev,
                        avatar: response.data.avatar
                    }));
                    setFeedback('Avatar updated successfully');
                } else {
                    throw new Error(response.data.error || 'Failed to update avatar. File size is too large.');
                }
            } catch (error) {
                console.error('Error uploading avatar:', error);
                setFeedback('Failed to update avatar');
                // Revert the image on error
                fetchUserData();
            }
        };
        reader.readAsDataURL(file);
    };

    const getAvatarUrl = (avatarPath) => {
        if (!avatarPath) return defaultAvatar;
        if (avatarPath.startsWith('data:image')) return avatarPath;
        if (avatarPath.startsWith('http')) return avatarPath;
        return `${process.env.REACT_APP_API_URL}${avatarPath.startsWith('/') ? '' : '/'}${avatarPath}`;
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handlePasswordReset = async () => {
        const redirectUri = `${window.location.origin}`;
        const tenant = "3shep";
        const policy = "B2C_1_pwd_reset";
        const clientId = process.env.REACT_APP_AZURE_B2C_CLIENT_ID;
        
        const b2cPasswordResetUrl = `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policy}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=id_token&redirect_uri=${encodeURIComponent(redirectUri)}&response_mode=fragment&scope=openid&nonce=${Math.random().toString(36)}`;

        window.location.href = b2cPasswordResetUrl;
    };

    return (
        <div className="grow">
            <div className="p-6 space-y-6">
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">My Profile</h2>
                
                {/* Picture */}
                <section>
                    <div className="flex items-center">
                        <div className="mr-4">
                            {!isLoading && (
                                <img 
                                    className="w-20 h-20 rounded-full object-cover" 
                                    src={getAvatarUrl(userData?.avatar)} 
                                    width="80" 
                                    height="80" 
                                    alt="User avatar" 
                                    onError={(e) => {
                                        if (e.target.src !== defaultAvatar) {
                                            console.log('Avatar load error, falling back to default');
                                            e.target.src = defaultAvatar;
                                        }
                                    }}
                                />
                            )}
                        </div>
                        <div className="flex flex-col space-y-2">
                            <input
                                type="file"
                                ref={fileInputRef}
                                className="hidden"
                                accept="image/jpeg,image/png"
                                onChange={handleAvatarChange}
                            />
                            <Button
                                onClick={triggerFileInput}
                                variant="contained"
                                startIcon={<PhotoCamera />}
                                size="small"
                                disabled={isLoading}
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#3b82f6',
                                    '&:hover': {
                                        backgroundColor: '#2563eb',
                                    }
                                }}
                            >
                                Change
                            </Button>
                            {feedback && (
                                <div className={`text-sm ${feedback.includes('success') ? 'text-green-600' : 'text-red-600'}`}>
                                    {feedback}
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                {/* Profile Details */}
                <section>
                    <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                        Profile Details
                    </h2>
                    <div className="text-sm">Manage your personal information.</div>
                    <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                        <div className="sm:w-1/3">
                            <label className="block text-sm font-medium mb-1" htmlFor="name">
                                Full Name
                            </label>
                            <input 
                                id="name" 
                                className="form-input w-full bg-slate-50 cursor-not-allowed" 
                                type="text" 
                                value={userData?.name || ''} 
                                disabled 
                                readOnly
                            />
                        </div>
                        <div className="sm:w-1/3">
                            <label className="block text-sm font-medium mb-1" htmlFor="email">
                                Email
                            </label>
                            <input 
                                id="email" 
                                className="form-input w-full bg-slate-50 cursor-not-allowed" 
                                type="email" 
                                value={userData?.email || ''} 
                                disabled 
                                readOnly
                            />
                        </div>
                    </div>
                </section>

                {/* Password */}
                <section>
                    <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Password</h2>
                    <div className="text-sm">You can set a permanent password if you don't want to use temporary login codes.</div>
                    <div className="mt-5">
                        <Button
                            onClick={handlePasswordReset}
                            variant="outlined"
                            startIcon={<LockReset />}
                            sx={{
                                textTransform: 'none',
                                borderColor: '#3b82f6',
                                color: '#3b82f6',
                                '&:hover': {
                                    borderColor: '#2563eb',
                                    backgroundColor: 'rgba(59, 130, 246, 0.04)',
                                }
                            }}
                        >
                            Reset Password
                        </Button>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default ProfilePanel;